import {
  allMappedCountries as countries,
  CountryCode,
  formatCountry,
  getPostalCodeProps,
  getRegionLabel,
  TaxEntityTypeUS,
  TaxFormType,
} from "@trolley/common-frontend";
import {
  Button,
  Collapse,
  Container,
  Divider,
  Form,
  GreyBox,
  Grid,
  Icon,
  Modal,
  Notification,
  TitleBar,
  Tooltip,
} from "components";
import { useForm } from "components/Form";
import { isRegionInCountryValidator } from "components/Form/SelectRegion";
import { getPostalLocaleId, getRegionLocaleId } from "pages/Info";
import CopyProfileAddress from "pages/TaxSection/CopyProfileAddress";
import CopyProfileName from "pages/TaxSection/CopyProfileName";
import TaxFooter from "pages/TaxSection/TaxFooter";
import { getIrsRule } from "pages/TaxSection/variables";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { notifySuccess } from "store/actions/notifications";
import { createTaxForm, TaxDataW9, TaxForm, updateTaxForm } from "store/actions/taxForms";
import { useRecipient } from "store/hooks/recipient";
import css, { createUseStyle } from "style/classname";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues, stringifyAddress } from "utils/helpers";
import { getUSTaxEntityTinType, idTypes, payeeCodes, reportingCodes, taxTypes, TIN_FORMAT } from "./variables";

const emptyTaxForm: Partial<TaxDataW9> = {
  // this needs to be an external constant because of useEffect dependency
  country: CountryCode.US,
};
interface Props {
  taxId: string | undefined;
  taxData: TaxDataW9 | undefined;
  loading?: boolean;
  onComplete(tf: TaxForm | { id: string }): void;
}

export default function TaxInformation(props: Props) {
  const [form] = useForm();
  const { taxId, taxData = emptyTaxForm, loading } = props;
  const [showPayeeCodes, setShowPayeeCodes] = useState(false);
  const [showFATCACodes, setShowFATCACodes] = useState(false);
  const [showCopyProfileName, setShowCopyProfileName] = useState<boolean | undefined>();
  const [showCopyRecipientAddress, setShowCopyRecipientAddress] = useState<boolean | undefined>();
  const [showDBAName, setShowDBAName] = useState(false);
  const recipient = useRecipient();
  const styledExemption = useStyledExemption();
  const { formatMessage } = useIntl();

  const irsRule = getIrsRule(formatMessage);

  useEffect(() => {
    form.setFieldsValue(taxData);
  }, [taxData]);

  async function onFinish(values: Partial<TaxDataW9>) {
    if (taxId && !form.isFieldsTouched()) {
      // editing a tax form. all values are valid, no need to update
      props.onComplete({ id: taxId } as TaxForm);
    } else {
      try {
        const formattedData = {
          kind: TaxFormType.W9,
          formVersion: "2017",
          data: omitMaskedValues(values),
        };
        const tf = taxId ? await updateTaxForm(taxId, formattedData) : await createTaxForm(formattedData);
        props.onComplete(tf);
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    }
  }

  return (
    <Form<TaxDataW9> form={form} onFinish={onFinish} initialValues={taxData}>
      <Container>
        <Helmet>
          <title>
            {formatMessage({
              id: "containers.tax.w9.personalInformation",
            })}
          </title>
        </Helmet>
        <TitleBar>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-9" })}</TitleBar>
        <TitleBar level={3}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({
                id: "containers.tax.w9.personalInformation",
              }),
              step: 1,
              totalSteps: 2,
            },
          )}
        </TitleBar>
        <GreyBox>
          <Button type="link" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noopener noreferrer">
            {formatMessage(
              { id: "containers.tax.formInstructions" },
              {
                form: "W-9",
              },
            )}
            <Icon type="external-link" right size="small" />
          </Button>
        </GreyBox>
        <Divider transparent />
        <Form.Field
          name="name"
          label={formatMessage({ id: "containers.tax.nameAsShown" })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: "containers.tax.nameAsShownRequired",
              }),
            },
            irsRule,
          ]}
          hint={
            showDBAName ? null : (
              <Tooltip
                placement="topLeft"
                title={formatMessage({
                  id: "containers.tax.nameAsShownTooltip",
                })}
              >
                <Button
                  type="link"
                  onClick={() => {
                    setShowDBAName(true);
                  }}
                >
                  {formatMessage({
                    id: "containers.tax.uploadTaxForm.addBusiness",
                  })}
                </Button>
              </Tooltip>
            )
          }
        >
          {(control, meta, { setFieldsValue }) => (
            <>
              <Form.Input
                {...control}
                name="name"
                onFocus={(e) => {
                  if (!e.target.value && showCopyProfileName === undefined && recipient?.name) {
                    setShowCopyProfileName(true);
                  }
                }}
              />
              <CopyProfileName
                label={formatMessage({ id: "containers.tax.nameAsShown" })}
                name={recipient?.name}
                visible={!!showCopyProfileName}
                onClose={() => {
                  setShowCopyProfileName(false);
                }}
                onOk={() => {
                  setFieldsValue({
                    name: recipient?.name,
                  });
                  setShowCopyProfileName(false);
                  notifySuccess(formatMessage({ id: "containers.tax.nameCopied" }));
                }}
              />
            </>
          )}
        </Form.Field>
        {showDBAName ? (
          <Form.Field
            name="dbaName"
            optional
            label={formatMessage({ id: "containers.tax.businessName" })}
            rules={[irsRule]}
          >
            <Form.Input name="dbaName" />
          </Form.Field>
        ) : null}
        <Grid>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="address"
              label={formatMessage({ id: "containers.info.street1.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.street1.required",
                  }),
                },
                irsRule,
              ]}
            >
              {(control, meta, { setFieldsValue }) => (
                <>
                  <Form.Input
                    {...control}
                    name="address"
                    onFocus={(e) => {
                      if (!e.target.value && showCopyRecipientAddress === undefined && recipient?.address?.country) {
                        setShowCopyRecipientAddress(true);
                      }
                    }}
                  />
                  <CopyProfileAddress
                    label={formatMessage({
                      id: "containers.info.street1.title",
                    })}
                    address={[
                      [recipient?.address.street1, recipient?.address.street2].filter((v) => v).join(" "),
                      stringifyAddress({
                        city: recipient?.address.city,
                        region: getRegionLabel(recipient?.address.region, recipient?.address.country),
                        postalCode: recipient?.address.postalCode,
                      }),
                      formatCountry(recipient?.address.country, formatMessage),
                    ]}
                    visible={!!showCopyRecipientAddress}
                    onClose={() => {
                      setShowCopyRecipientAddress(false);
                    }}
                    onOk={() => {
                      if (recipient) {
                        setFieldsValue({
                          address: [recipient.address.street1, recipient.address.street2].filter((v) => v).join(" "),
                          city: recipient.address.city,
                          state: recipient.address.region,
                          zip: recipient.address.postalCode,
                          country: recipient.address.country,
                        });
                        notifySuccess(formatMessage({ id: "containers.tax.addressCopied" }));
                      }
                      setShowCopyRecipientAddress(false);
                    }}
                  />
                </>
              )}
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="city"
              label={formatMessage({ id: "containers.info.city.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.city.required",
                  }),
                },
                irsRule,
              ]}
            >
              <Form.Input name="city" />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field<TaxDataW9>
              name="country"
              label={formatMessage({ id: "containers.info.country.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.country.required",
                  }),
                },
              ]}
            >
              <Form.SelectCountry type="all" name="country" />
            </Form.Field>
          </Grid.Item>

          <Form.Control dependencies={["country"]}>
            {({ value: { country } }) => {
              const countryHasRegions = !!countries[country]?.regions.length;
              const postalCodeProps = getPostalCodeProps(country, formatMessage);

              return (
                <>
                  {countryHasRegions && (
                    <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                      <Form.Field
                        name="state"
                        key={country}
                        label={formatMessage({
                          id: getRegionLocaleId(country, "title"),
                          defaultMessage: formatMessage({
                            id: "containers.tax.w9.stateRegion",
                          }),
                        })}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: getRegionLocaleId(country, "required"),
                              defaultMessage: formatMessage({
                                id: "containers.info.region.required",
                              }),
                            }),
                          },
                          isRegionInCountryValidator("country", formatMessage),
                        ]}
                      >
                        <Form.SelectRegion name="state" country={country} />
                      </Form.Field>
                    </Grid.Item>
                  )}

                  {postalCodeProps.hasPostalCode && (
                    <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                      <Form.Field
                        name="zip"
                        label={formatMessage({
                          id: getPostalLocaleId(country, "title"),
                        })}
                        hint={postalCodeProps.hint}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: getPostalLocaleId(country, "required"),
                            }),
                          },
                          {
                            async validator(_: any, v: any) {
                              if (v && country === CountryCode.US && !postalCodeProps.isPostalCode?.(v)) {
                                throw formatMessage({
                                  id: "containers.info.postalCode.US.valid",
                                });
                              }
                            },
                          },
                          irsRule,
                        ]}
                      >
                        <Form.Input name="zip" />
                      </Form.Field>
                    </Grid.Item>
                  )}
                </>
              );
            }}
          </Form.Control>
        </Grid>
        <TitleBar level={3}>
          {formatMessage({ id: "containers.tax.w9.partII" }, { title: formatMessage({ id: "containers.tax.tin" }) })}
        </TitleBar>
        <Form.Control dependencies={["taxType"]}>
          {({ value: { taxType } }) =>
            taxType === TaxEntityTypeUS.LLC_SINGLE && (
              <Notification
                title={formatMessage(
                  { id: "containers.tax.w9.important" },
                  {
                    a: (content) => (
                      <Button
                        type="link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.irs.gov/businesses/small-businesses-self-employed/single-member-limited-liability-companies"
                      >
                        {content}
                      </Button>
                    ),
                  },
                )}
                type="warning"
              />
            )
          }
        </Form.Control>
        <Grid>
          <Form.Control dependencies={["taxType", "idType"]}>
            {({ value: { taxType, idType } }, meta, { setFieldsValue }) => {
              const tinTypes = getUSTaxEntityTinType(taxType);
              const tinType = tinTypes.length === 1 ? tinTypes[0] : (idType as "ssn" | "tin" | undefined);

              return (
                <>
                  <Grid.Item xs={24} sm={12}>
                    <Form.Field
                      name="taxType"
                      label={formatMessage({
                        id: "containers.tax.classification",
                      })}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: "containers.tax.classificationRequired",
                          }),
                        },
                      ]}
                    >
                      <Form.Select
                        name="taxType"
                        options={taxTypes}
                        onChange={(e) => {
                          setFieldsValue({
                            taxId: undefined,
                          });
                        }}
                      />
                    </Form.Field>
                  </Grid.Item>

                  {tinTypes.length > 1 && (
                    <Grid.Item xs={24} sm={12}>
                      <Form.Field
                        name="idType"
                        label={formatMessage({
                          id: "containers.tax.taxIdType",
                        })}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: "containers.tax.taxIdTypeRequired",
                            }),
                          },
                        ]}
                      >
                        <Form.Select
                          name="idType"
                          options={idTypes}
                          onChange={() => {
                            // When taxType changes, the tax id type can change from "SSN" to "TIN", but if taxId is already set as "*--**123", taxId will not update and the mask formatting will be wrong
                            setFieldsValue({
                              taxId: undefined,
                            });
                          }}
                        />
                      </Form.Field>
                    </Grid.Item>
                  )}

                  <Grid.Item xs={24} sm={12}>
                    <Form.Field
                      name="taxId"
                      key={String([taxType, tinType])}
                      label={
                        tinType
                          ? tinType === "ssn"
                            ? formatMessage({ id: "containers.tax.ssn" })
                            : formatMessage({ id: "containers.tax.ein" })
                          : formatMessage({ id: "containers.tax.tin" })
                      }
                      rules={
                        tinType
                          ? [
                              {
                                required: true,
                                message:
                                  tinType === "ssn"
                                    ? formatMessage({
                                        id: "containers.tax.ssnRequired",
                                      })
                                    : formatMessage({
                                        id: "containers.tax.einRequired",
                                      }),
                              },
                              {
                                async validator(_: any, value: string) {
                                  if (tinType && value && !value.includes("*") && value.replace(/\D/g, "").length < 9) {
                                    throw formatMessage(
                                      { id: "containers.tax.tinValidation" },
                                      { tinName: tinType.toLocaleUpperCase() },
                                    );
                                  }
                                },
                              },
                            ]
                          : []
                      }
                    >
                      <Form.InputMask
                        name="taxId"
                        mask={(tinType && TIN_FORMAT[tinType]) || ""}
                        placeholder={tinType === "ssn" ? "XXX-XX-XXXX" : "XX-XXXXXXX"}
                        disabled={!tinType}
                      />
                    </Form.Field>
                  </Grid.Item>
                </>
              );
            }}
          </Form.Control>
        </Grid>
        <Divider transparent />
        <Collapse>
          <Collapse.Panel
            header={
              <TitleBar level={3} style={{ margin: 0 }}>
                {formatMessage({ id: "containers.tax.w9.exemptions" })}
              </TitleBar>
            }
            showArrow={false}
            className={styledExemption}
          >
            <p>{formatMessage({ id: "containers.tax.w9.exemptionsLong" })}</p>
            <Grid>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="exemptPayee"
                  label={formatMessage({
                    id: "containers.tax.w9.exemptPayeeCode",
                  })}
                  hint={
                    <Button
                      type="link"
                      onClick={() => {
                        setShowPayeeCodes(true);
                      }}
                    >
                      {formatMessage({ id: "containers.tax.w9.payeeCodes" })}
                    </Button>
                  }
                >
                  <Form.Select name="exemptPayee" options={payeeCodes} />
                </Form.Field>
                <Modal
                  open={showPayeeCodes}
                  onCancel={() => {
                    setShowPayeeCodes(false);
                  }}
                  cancelText={formatMessage({ id: "common.close" })}
                  title={formatMessage({ id: "containers.tax.w9.payeeCodes" })}
                >
                  <ol className={styledExemptList}>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.exempt",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.theUS",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.aState",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.government",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.corp",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.dealer",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.futures",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.realEstate",
                      })}
                    </li>

                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.entity",
                      })}
                      ;
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.trust",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.financial",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.middleman",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptPayeeList.trustExempt",
                      })}
                    </li>
                  </ol>
                </Modal>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="exemptReporting"
                  label={formatMessage({ id: "containers.tax.w9.exemptFatca" })}
                  hint={
                    <Button
                      type="link"
                      onClick={() => {
                        setShowFATCACodes(true);
                      }}
                    >
                      {formatMessage({
                        id: "containers.tax.w9.reportingCodes",
                      })}
                    </Button>
                  }
                >
                  <Form.Select name="exemptReporting" options={reportingCodes} />
                </Form.Field>
                <Modal
                  open={showFATCACodes}
                  onCancel={() => {
                    setShowFATCACodes(false);
                  }}
                  cancelText={formatMessage({ id: "common.close" })}
                  title={formatMessage({
                    id: "containers.tax.w9.reportingCodes",
                  })}
                >
                  <ol type="A" className={styledExemptList}>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.exempt",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.theUS",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.aState",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.corpA",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.corpB",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.dealer",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.realEstate",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.investment",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.trust",
                      })}{" "}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.bank",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.broker",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.trustExempt",
                      })}
                    </li>
                    <li>
                      {formatMessage({
                        id: "containers.tax.w9.exemptFatcaList.taxExempt",
                      })}
                    </li>
                  </ol>
                </Modal>
              </Grid.Item>
            </Grid>
          </Collapse.Panel>
        </Collapse>
        <TaxFooter loading={loading} />
      </Container>
    </Form>
  );
}

const styledExemptList = css`
  li {
    padding: 2px 8px;
  }
`();

const useStyledExemption = createUseStyle(({ theme }) =>
  css`
    .rc-collapse-header {
      background-color: ${theme.colorFillSecondary};
      border-bottom: 1px solid ${theme.colorBorder};
    }
  `(),
);
