import { getFtins, FTINType } from "@trolley/common-frontend";
import React, { useMemo } from "react";

import { useIntl } from "utils/context";
import Select, { SelectProps } from "./Select";
import { DefaultOptionType } from "antd/es/select";

interface Props extends Omit<SelectProps<string>, "options"> {
  country: string | undefined | null;
  addOtherOption?: boolean;
}

export default function SelectFTINType(props: Props) {
  const { formatMessage } = useIntl();
  const { showSearch = true, country, addOtherOption, value, ...rest } = props;
  const options = useMemo(() => {
    const otherLabel = formatMessage({ id: "components.formTinTypes.other" });
    const fTinsByCountry = getFtins();
    const otherOption = { label: otherLabel, value: "other", "data-search": otherLabel, "aria-label": otherLabel };
    if (!country) {
      return [];
    }
    const fTins: FTINType = fTinsByCountry[country] ? fTinsByCountry[country] : [];

    return [
      ...Object.entries(fTins).map(
        ([code, fTinData]) =>
          ({
            label: fTinData?.name ?? "",
            value: code,
            "data-search": [fTinData?.name ?? "", code].join(" "),
            "aria-label": fTinData?.name ?? "",
          } as DefaultOptionType),
      ),
      ...(!!addOtherOption ? [otherOption] : []),
    ];
  }, [country]);

  return (
    <Select
      {...rest}
      showSearch={showSearch}
      placeholder={formatMessage({ id: "components.formTinTypes.select" })}
      optionFilterProp="data-search"
      value={value ?? undefined}
      options={options}
      aria-label={formatMessage({ id: "components.formTinTypes.select" })}
    />
  );
}
