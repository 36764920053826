import React, { useState } from "react";

import { getFtins } from "@trolley/common-frontend";
import { FormInstance } from "antd";
import { Button, Container, Divider, TinHelp, Form2, GreyBox, Icon, Typography } from "components";
import { useIntl } from "utils/context";

export interface ITINFormData {
  tinCountry: string;
  tinType: string;
  tinNumber: string;
  otherTinDescription?: string;
}

enum TINFormStages {
  SELECT_TYPE = "selectType",
  ENTER_TIN = "enterTin",
}

interface Props {
  isBusiness: boolean;
  onSaveForm: (formData: ITINFormData) => void;
  onCloseForm: () => void;
  tinNumbersCount: number;
  onNoTIN: () => void;
}

export default function EnterTINForm({ isBusiness, onSaveForm, onCloseForm, tinNumbersCount, onNoTIN }: Props) {
  const { formatMessage } = useIntl();
  const [form] = Form2.useForm<ITINFormData>();
  const [tinData, setTinData] = useState<ITINFormData | null>(null);
  // const [autoFocus, setAutoFocus] = useState(false);
  const [formStage, setFormStage] = useState<TINFormStages>(TINFormStages.SELECT_TYPE);
  const initialValues: ITINFormData = {
    tinCountry: "",
    tinNumber: "",
    tinType: "",
  };

  function onBackClick() {
    onCloseForm();
    setTinData(null);
    setFormStage(TINFormStages.SELECT_TYPE);
  }

  function onSubmit(tinNumber: ITINFormData) {
    if (formStage === TINFormStages.ENTER_TIN) {
      onSaveForm({
        ...tinData,
        ...tinNumber,
      });
      setTinData(null);
      setFormStage(TINFormStages.SELECT_TYPE);
    } else {
      // formStage === TINFormStages.SELECT_TYPE
      setFormStage(TINFormStages.ENTER_TIN);
      setTinData({ ...tinNumber });
    }
  }

  function getTinTypeLabel() {
    if (!tinData || !tinData.tinCountry || !tinData.tinType || tinData.tinType === "other") return "";
    const fTins = getFtins();

    return fTins?.[tinData.tinCountry]?.[tinData.tinType]?.name ?? "";
  }

  function changeTinType() {
    setFormStage(TINFormStages.SELECT_TYPE);
  }

  return (
    <>
      {tinNumbersCount > 0 && (
        <>
          <Button type="link" onClick={onBackClick} icon="angle-left">
            <Typography.Text underline style={{ fontStyle: "italic" }} inline>
              {formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.title" })}
            </Typography.Text>
          </Button>
          <Divider transparent size="large" />
        </>
      )}
      <Form2
        form={form}
        initialValues={initialValues}
        validateTrigger="onChange"
        requiredMark={false}
        onFinish={onSubmit}
      >
        <Container padding="none">
          <fieldset>
            <legend>
              {formStage === TINFormStages.SELECT_TYPE && (
                <Typography.Title>
                  {isBusiness
                    ? formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberBusinessTitle" })
                    : formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberIndividualTitle" })}
                </Typography.Title>
              )}
              {formStage === TINFormStages.ENTER_TIN && tinData?.tinType !== "other" && (
                <>
                  <Typography.Title>
                    {formatMessage(
                      { id: "containers.taxProfile.wizard.enterTinNumberFormEnterType" },
                      { tinType: getTinTypeLabel() },
                    )}
                  </Typography.Title>
                  <Divider transparent size="small" />
                  {tinData && (
                    <>
                      <TinHelp country={tinData.tinCountry} ftinType={tinData?.tinType} />
                      <Divider transparent />
                    </>
                  )}
                </>
              )}
              {formStage === TINFormStages.ENTER_TIN && tinData?.tinType === "other" && (
                <Typography.Title>
                  {formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberFormEnterTIN" })}
                </Typography.Title>
              )}
            </legend>
            {formStage === TINFormStages.SELECT_TYPE && (
              <Typography.Paragraph>
                {isBusiness
                  ? formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberBusiness" })
                  : formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberIndividual" })}
              </Typography.Paragraph>
            )}
            {formStage === TINFormStages.ENTER_TIN && tinData?.tinType === "other" && (
              <GreyBox border={false}>
                <Typography.Paragraph>
                  {formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberFormEnterTINText" })}
                </Typography.Paragraph>
              </GreyBox>
            )}
            <Divider transparent size="large" />
            {formStage === TINFormStages.SELECT_TYPE && (
              <>
                <Form2.Item
                  name="tinCountry"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.taxProfile.wizard.enterTinNumberCountryMessage",
                      }),
                    },
                  ]}
                  style={{ marginBottom: "16px" }}
                >
                  <Form2.SelectCountry autoFocus />
                </Form2.Item>
                <Form2.Item dependencies={["tinCountry"]} style={{ marginBottom: 0 }}>
                  {(form: FormInstance<ITINFormData>) => {
                    const tinCountry = form.getFieldValue("tinCountry");
                    form.setFieldValue("tinType", "");

                    return (
                      <Form2.Item
                        name="tinType"
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: "containers.taxProfile.wizard.enterTINNumberTINTypeMessage",
                            }),
                          },
                        ]}
                        style={{ marginBottom: "16px" }}
                      >
                        <Form2.SelectFTINType disabled={!tinCountry} country={tinCountry} addOtherOption />
                      </Form2.Item>
                    );
                  }}
                </Form2.Item>
              </>
            )}
            {formStage === TINFormStages.ENTER_TIN && (
              <>
                {tinData?.tinType !== "other" && (
                  <Form2.Item
                    name="tinNumber"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: "containers.taxProfile.wizard.enterTinNumberTINMessage",
                        }),
                      },
                    ]}
                    style={{ marginBottom: "16px" }}
                    label={getTinTypeLabel()}
                  >
                    <Form2.Input
                      type="text"
                      placeholder={formatMessage({ id: "containers.taxProfile.wizard.enterTinNumber" })}
                      maxLength={20}
                    />
                  </Form2.Item>
                )}
                {tinData?.tinType === "other" && (
                  <>
                    <Form2.Item
                      name="otherTinDescription"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: "containers.taxProfile.wizard.enterTinNumberTINMessage",
                          }),
                        },
                      ]}
                      style={{ marginBottom: "16px" }}
                      label={formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberOtherType" })}
                    >
                      <Form2.Input type="text" placeholder="Enter Type of TIN" maxLength={20} />
                    </Form2.Item>
                    <Form2.Item
                      name="tinNumber"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: "containers.taxProfile.wizard.enterTinNumberTINMessage",
                          }),
                        },
                      ]}
                      style={{ marginBottom: "16px" }}
                      label={formatMessage({ id: "containers.taxProfile.wizard.enterTinNumber" })}
                    >
                      <Form2.Input
                        type="text"
                        placeholder={formatMessage({ id: "containers.taxProfile.wizard.enterTinNumber" })}
                        maxLength={20}
                      />
                    </Form2.Item>
                  </>
                )}
                <Divider transparent />
                <Button type="link" onClick={changeTinType}>
                  {formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberChooseAnotherType" })}
                </Button>
              </>
            )}
          </fieldset>
          {tinNumbersCount === 0 && formStage === TINFormStages.SELECT_TYPE && (
            <>
              <Divider transparent size="large" />
              <Button type="link" onClick={onNoTIN}>
                {formatMessage({ id: "containers.taxProfile.wizard.enterTinNumberDoesntIssue" })}
              </Button>
            </>
          )}
          <Divider transparent size="large" />
          <Form2.SubmitButton
            disabled={false}
            text={formatMessage({ id: "common.continue" })}
            suffix={<Icon type="arrow-right" />}
          />
        </Container>
      </Form2>
    </>
  );
}
