import React from "react";

import { BackButton, Button, Footer, Form2 as Form } from "components";
import { RecipientAccount } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";

interface Props {
  account: RecipientAccount | undefined;
  busy: boolean;
}

export default function PayoutFooter({ account, busy }: Props) {
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const submitLabel = formatMessage({
    id: account && account.id ? "common.save" : "common.add",
  });
  const showPrimaryCheckbox = !account?.primary && recipient?.accounts.some((acc) => acc.primary);

  return (
    <>
      {showPrimaryCheckbox && (
        <Form.Item name="primary" valuePropName="checked">
          <Form.Checkbox>
            {formatMessage({
              id: "containers.accountSummary.buttons.makeActive",
            })}
          </Form.Checkbox>
        </Form.Item>
      )}
      <Footer
        main={
          <Button size="large" htmlType="submit" type="primary" loading={busy}>
            {submitLabel}
          </Button>
        }
        extra={<BackButton />}
      />
    </>
  );
}
