import React, { useMemo } from "react";
import { VerificationRejectedReasons, VerificationStatus } from "@trolley/common-frontend";
import { Tag } from "antd";
import { Icon, Typography } from "components";
import { useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";

interface Props {
  status: VerificationStatus;
  rejectedReason?: VerificationRejectedReasons;
}

export default function IdvStatusDisplay({ status, rejectedReason = VerificationRejectedReasons.OTHER }: Props) {
  const { formatMessage } = useIntl();
  const { isMobile } = useWindowSize();

  const TagInfo = useMemo(
    () => ({
      [VerificationStatus.APPROVED]: {
        icon: <Icon type="check" style={{ color: "#000302" }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.approved" }),
        // color: "success",
        color: "#C5DBCC",
        textColor: "#000302",
      },
      [VerificationStatus.PENDING]: {
        icon: <Icon type="clock" style={{ color: "#000302" }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.pending" }),
        // color: "processing",
        color: "#A3CEFF",
        textColor: "#000302",
      },
      [VerificationStatus.REJECTED]: {
        icon: <Icon type="circle-exclamation" style={{ color: "#61001A" }} size="large" />,
        label: `${formatMessage({ id: "containers.trust.verificationStatus.rejected" })}-${formatMessage({
          id: `containers.trust.rejectedReasons.${rejectedReason}`,
        })}`,
        // color: "error",
        color: rejectedReason === VerificationRejectedReasons.FRAUDULENT ? "#FFE6E6" : "#FFDFA3",
        textColor: rejectedReason === VerificationRejectedReasons.FRAUDULENT ? "#61001A" : "#612500",
      },
      [VerificationStatus.RETRY]: {
        icon: <Icon type="clock" style={{ color: "#612500" }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.retry" }),
        // color: "warning",
        color: "#FFDFA3",
        textColor: "#612500",
      },
      [VerificationStatus.REVIEW]: {
        icon: <Icon type="clock" style={{ color: "#000302" }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.review" }),
        // color: "processing",
        color: "#A3CEFF",
        textColor: "#000302",
      },
      [VerificationStatus.SUBMITTED]: {
        icon: <Icon type="clock" style={{ color: "#000302" }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.submitted" }),
        // color: "processing",
        color: "#A3CEFF",
        textColor: "#000302",
      },
      [VerificationStatus.EXPIRED]: {
        icon: <Icon type="check" style={{ color: "#000302" }} />,
        label: formatMessage({ id: "containers.trust.verificationStatus.expired" }),
        // color: "default",
        color: "#B5B5B5",
        textColor: "#000302",
      },
    }),
    [formatMessage],
  );

  const { icon, label, color, textColor } = TagInfo[status];

  return (
    <>
      <Tag icon={icon} color={color} style={{ borderRadius: 4 }}>
        <Typography.Text
          size={isMobile ? "base" : "large"}
          uppercase
          inline
          style={{ marginLeft: "5px", color: textColor }}
        >
          {label}
        </Typography.Text>
      </Tag>
    </>
  );
}
