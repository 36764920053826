import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import React from "react";

import Button, { ButtonProps } from "components/Button";
import Space from "components/Space";
import { common } from "locale/en";
import { classnames, createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";
import Icon from "components/Icon";
import useModal from "./useModal";

interface Props extends Omit<AntModalProps, "visible" | "okButtonProps" | "cancelButtonProps"> {
  size?: "small" | "default";
  okButtonProps?: Omit<AntModalProps["okButtonProps"], "icon"> & Pick<ButtonProps, "icon" | "iconProps" | "disabled">;
  cancelButtonProps?: Omit<AntModalProps["cancelButtonProps"], "icon"> & Pick<ButtonProps, "icon" | "iconProps">;
}

export default function Modal(props: Props) {
  const intl = useIntl();
  const styledModal = useStyledModal();
  const {
    size,
    width = size === "small" ? 400 : 600,
    okText = intl.formatMessage({ id: "common.done", defaultMessage: common.done }),
    okType = "primary",
    okButtonProps,
    closeIcon = <Icon type="times" />,
    cancelText = intl.formatMessage({
      id: "common.cancel",
      defaultMessage: common.cancel,
    }),
    cancelButtonProps,
    className,
    footer,
    ...rest
  } = props;

  return (
    <AntModal
      maskClosable
      closable
      width={width}
      closeIcon={closeIcon}
      okText={okText}
      cancelText={cancelText}
      centered
      className={classnames(styledModal, className)}
      footer={
        footer ?? (
          <Space direction="row-reverse">
            {props.onOk && (
              <Button
                onClick={(e: any) => {
                  props.onOk?.(e);
                }}
                type={okType}
                size="large"
                loading={props.confirmLoading}
                {...okButtonProps}
              >
                {okText}
              </Button>
            )}
            <Button
              onClick={(e: any) => {
                props.onCancel?.(e);
              }}
              size="large"
              type={!props.onOk ? "primary" : "default"}
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
          </Space>
        )
      }
      {...rest}
    />
  );
}

const useStyledModal = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-modal-close {
      border-radius: 50%;
    }
    .${theme.prefixCls}-modal-body {
      max-height: calc(100vh - 220px);
      overflow-y: scroll;
    }
  `(),
);

Modal.useModal = useModal;
