/* istanbul ignore file */
import { useState, useEffect } from "react";
import { useTheme } from "style/classname";

function getSize(mobileBreakpoint: number) {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: typeof window === "object" ? window.innerWidth < mobileBreakpoint : false,
  };
}

export default function useWindowSize() {
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState(getSize(theme.screenMD));

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize(theme.screenMD));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.screenMD]);

  return windowSize;
}
