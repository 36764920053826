import {
  RecipientType,
  VerificationRejectedReasons,
  VerificationStatus,
  formatCountry,
} from "@trolley/common-frontend";
import { Button, Container, Divider, Grid, Icon, List, Notification, Space, Text, TitleBar } from "components";
import React, { useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { IndividualVerificationResponse, KybVerificationResponse } from "store/actions/idVerification";
import { useVerifications } from "store/hooks/idVerification";
import { useRecipient } from "store/hooks/recipient";
import IdVerificationButton, { isVerificationInProgress } from "./IdVerificationButton";
import IdvStatusDisplay from "./IdvStatusDisplay";
import dayjs from "dayjs";
import { Tree } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { DataNode } from "antd/es/tree";
import RejectedNotification from "./RejectedNotification";
import { FormatMessage } from "utils/context";
import BasicCardLayout from "./BasicCardLayout";
import { useWindowSize } from "utils/hooks";

export default function VerificationHistory() {
  const { formatMessage } = useIntl();
  const recipient = useRecipient();
  const { data: verifications } = useVerifications(recipient?.id ?? "");

  const verificationInProgress = useMemo(() => isVerificationInProgress(recipient), [recipient]);

  function renderIndividualVerification(item: IndividualVerificationResponse) {
    return <SingleVerification verification={item} recipientType={RecipientType.INDIVIDUAL} />;
  }

  function renderIndividualVerificationList(
    reverificationAllowed: boolean,
    formatMessage: IntlShape["formatMessage"] | FormatMessage,
  ) {
    const { individual } = verifications ?? { individual: [] };
    const reasonType =
      individual.length > 0 && individual[0].status === VerificationStatus.REJECTED ? individual[0].reasonType : null;
    const showNotification = reverificationAllowed && reasonType && reasonType !== VerificationRejectedReasons.OTHER;

    return (
      <>
        {individual.length > 0 && (
          <>
            {showNotification && (
              <RejectedNotification rejectedReason={reasonType} reverificationAllowed={reverificationAllowed} />
            )}
            {showNotification && <Divider transparent />}
            <List<IndividualVerificationResponse>
              items={individual}
              renderItem={renderIndividualVerification}
              defaultRecordCount={1}
              showLessText={formatMessage({ id: "containers.trust.showLessIndividual" })}
              showMoreText={formatMessage({ id: "containers.trust.showMoreIndividual" })}
            />
          </>
        )}
      </>
    );
  }

  function renderBusinessVerification(item: KybVerificationResponse) {
    return <SingleVerification verification={item} recipientType={RecipientType.BUSINESS} />;
  }

  function renderBusinessVerificationList(
    reverificationAllowed: boolean,
    formatMessage: IntlShape["formatMessage"] | FormatMessage,
  ) {
    const { business } = verifications ?? { business: [] };
    const reasonType =
      business.length > 0 && business[0].status === VerificationStatus.REJECTED ? business[0].reasonType : null;
    const showNotification = reverificationAllowed && reasonType && reasonType !== VerificationRejectedReasons.OTHER;

    return (
      <>
        {business.length > 0 && (
          <>
            {showNotification && (
              <RejectedNotification rejectedReason={reasonType} reverificationAllowed={reverificationAllowed} />
            )}
            {showNotification && <Divider transparent />}
            <List<KybVerificationResponse>
              items={business}
              renderItem={renderBusinessVerification}
              defaultRecordCount={1}
              showLessText={formatMessage({ id: "containers.trust.showLessBusiness" })}
              showMoreText={formatMessage({ id: "containers.trust.showMoreBusiness" })}
            />
          </>
        )}
      </>
    );
  }

  return (
    <Container>
      <TitleBar level={2}>{formatMessage({ id: "containers.trust.verifications" })}</TitleBar>
      {renderIndividualVerificationList(!!recipient?.verificationAttemptAvailable, formatMessage)}
      {(verifications?.business.length ?? 0) > 0 && (verifications?.individual.length ?? 0) > 0 && (
        <Divider transparent />
      )}
      {renderBusinessVerificationList(!!recipient?.verificationAttemptAvailable, formatMessage)}
      <Divider transparent />
      {(verificationInProgress || recipient?.verificationAttemptAvailable) && (
        <IdVerificationButton
          reVerification={
            (recipient?.idvStatus && recipient.type === RecipientType.INDIVIDUAL) ||
            (recipient?.kybStatus && recipient.type === RecipientType.BUSINESS)
          }
          placement="right"
        />
      )}
    </Container>
  );
}

function SingleVerification({
  verification,
  recipientType,
}: {
  verification: IndividualVerificationResponse | KybVerificationResponse;
  recipientType: RecipientType;
}) {
  const { formatMessage } = useIntl();
  const [expanded, setExpanded] = useState(false);

  const showExpandButton =
    verification.status === VerificationStatus.APPROVED ||
    (verification.status === VerificationStatus.REJECTED &&
      verification.reasonType === VerificationRejectedReasons.MISSING_REQUIREMENTS);

  return (
    <Container padding="medium" style={{ paddingRight: 0 }}>
      <BasicCardLayout
        recipientType={recipientType}
        submittedAt={verification.submittedAt}
        statusElement={
          <IdvStatusDisplay
            status={verification.status}
            {...(verification.reasonType ? { rejectedReason: verification.reasonType } : null)}
          />
        }
        actionButtons={
          showExpandButton ? (
            <Button
              type="text"
              shape="round"
              icon={expanded ? "angle-up" : "angle-down"}
              onClick={() => {
                setExpanded((e) => !e);
              }}
              aria-label={
                expanded
                  ? formatMessage({
                      id: "common.collapse",
                    })
                  : formatMessage({
                      id: "common.expand",
                    })
              }
            />
          ) : undefined
        }
      />
      {verification.status === VerificationStatus.SUBMITTED && (
        <Container padding="small" style={{ marginTop: 10 }}>
          <Notification type="info" title={formatMessage({ id: "containers.trust.verificationAlert.submitted" })} />
        </Container>
      )}
      {verification.status === VerificationStatus.REJECTED &&
        verification.reasonType !== VerificationRejectedReasons.OTHER && (
          <Container padding="none" style={{ marginTop: 10 }}>
            <Notification
              type="warning"
              title={formatMessage({
                id: `containers.trust.verificationAlert.rejected.${verification.reasonType}.${recipientType}`,
              })}
            />
          </Container>
        )}
      {expanded && <Divider transparent size="large" />}
      {expanded && recipientType === RecipientType.INDIVIDUAL && (
        <ShowIndividualTree verification={verification as IndividualVerificationResponse} />
      )}
      {expanded && recipientType === RecipientType.BUSINESS && (
        <ShowBusinessTree verification={verification as KybVerificationResponse} />
      )}
    </Container>
  );
}

function ShowIndividualTree({ verification: { verifiedData } }: { verification: IndividualVerificationResponse }) {
  enum IDVerificationFields {
    FirstName = "firstName",
    LastName = "lastName",
    DocumentType = "documentType",
    DocumentValidFrom = "documentValidFrom",
    DocumentValidUntil = "documentValidUntil",
    DocumentIssuingCountry = "documentIssuingCountry",
    Street1 = "street1",
    Street2 = "street2",
    City = "city",
    Country = "country",
    Region = "region",
    PostalCode = "postalCode",
  }

  const IdVerificationOrder = [
    IDVerificationFields.FirstName,
    IDVerificationFields.LastName,
    IDVerificationFields.DocumentType,
    IDVerificationFields.DocumentValidFrom,
    IDVerificationFields.DocumentValidUntil,
    IDVerificationFields.DocumentIssuingCountry,
    IDVerificationFields.Street1,
    IDVerificationFields.Street2,
    IDVerificationFields.City,
    IDVerificationFields.Region,
    IDVerificationFields.Country,
    IDVerificationFields.PostalCode,
  ];

  const ErrorChildrenDataTree: DataNode[] = [];
  const VerifiedChildrenDataTree: DataNode[] = [];
  const ErrorDataTree: DataNode[] = [
    {
      title: (
        <Space direction="column" align="start" inline>
          <Text>Error</Text>
          <Text type="secondary">The following items were missing or impossible to validate</Text>
        </Space>
      ),
      key: "0-0",
      children: ErrorChildrenDataTree,
    },
  ];
  const VerifiedDataTree: DataNode[] = [
    {
      title: "Verified",
      key: "0-0",
      children: VerifiedChildrenDataTree,
    },
  ];

  const addVerifiedChildren = (fieldTitle: string, value: string | null, key: string) => {
    VerifiedChildrenDataTree.push({
      title: (
        <Space direction="column" align="start" inline>
          <Text>{fieldTitle}</Text>
          <Text>{value ?? "Unable to verified"}</Text>
        </Space>
      ),
      key,
      icon: <Icon type="circle-check" theme="solid" color="success" />,
    });
  };

  const addErrorChildren = (fieldTitle: string, value: string | null, key: string) => {
    ErrorChildrenDataTree.push({
      title: (
        <Space direction="column" align="start" inline>
          <Text>{fieldTitle}</Text>
          <Text>{value ?? "Unable to verified"}</Text>
        </Space>
      ),
      key,
      icon: <Icon type="circle-exclamation" theme="solid" color="warning" />,
    });
  };

  const addNode = (fieldTitle: string, value: string | null, key: string) => {
    if (!!value) {
      addVerifiedChildren(fieldTitle, value, key);
    } else {
      addErrorChildren(fieldTitle, value, key);
    }
  };

  IdVerificationOrder.forEach((idVerificationField: IDVerificationFields, index: number) => {
    switch (idVerificationField) {
      case IDVerificationFields.FirstName:
        addNode("Fist Name", verifiedData.firstName, `0-0-${index}`);
        break;
      case IDVerificationFields.LastName:
        addNode("Last Name", verifiedData.lastName, `0-0-${index}`);
        break;
      case IDVerificationFields.DocumentType:
        addNode("Document Type", verifiedData.documentType, `0-0-${index}`);
        break;
      case IDVerificationFields.DocumentValidFrom:
        addNode(
          "Document Issue Date",
          !!verifiedData.documentValidFrom
            ? dayjs(verifiedData.documentValidFrom).isValid()
              ? dayjs(verifiedData.documentValidFrom).format("LL")
              : verifiedData.documentValidFrom
            : null,
          `0-0-${index}`,
        );
        break;
      case IDVerificationFields.DocumentValidUntil:
        addNode(
          "Document Expiration Date",
          !!verifiedData.documentValidUntil
            ? dayjs(verifiedData.documentValidUntil).isValid()
              ? dayjs(verifiedData.documentValidUntil).format("LL")
              : verifiedData.documentValidUntil
            : null,
          `0-0-${index}`,
        );
        break;
      case IDVerificationFields.DocumentIssuingCountry:
        addNode(
          "Document Issue Country",
          verifiedData.documentIssuingCountry ? formatCountry(verifiedData.documentIssuingCountry) : null,
          `0-0-${index}`,
        );
        break;
      case IDVerificationFields.Street1:
        addNode("Street address 1", verifiedData.address.street1, `0-0-${index}`);
        break;
      case IDVerificationFields.Street2:
        addNode("Street address 2", verifiedData.address.street2, `0-0-${index}`);
        break;
      case IDVerificationFields.City:
        addNode("City", verifiedData.address.city, `0-0-${index}`);
        break;
      case IDVerificationFields.Region:
        addNode("Region", verifiedData.address.region, `0-0-${index}`);
        break;
      case IDVerificationFields.Country:
        addNode(
          "Country",
          verifiedData.address.country ? formatCountry(verifiedData.address.country) : null,
          `0-0-${index}`,
        );
        break;
      case IDVerificationFields.PostalCode:
        addNode("Postal Code", verifiedData.address.postalCode, `0-0-${index}`);
        break;
    }
  });

  return (
    <VerificationTree
      verifiedChildrenDataTree={VerifiedChildrenDataTree}
      errorChildrenDataTree={ErrorChildrenDataTree}
      verifiedDataTree={VerifiedDataTree}
      errorDataTree={ErrorDataTree}
    />
  );
}

function ShowBusinessTree({ verification: { verifiedData } }: { verification: KybVerificationResponse }) {
  enum KybVerificationFields {
    Name = "name",
    BusinessRegistrationNumber = "businessRegistrationNumber",
    Street1 = "street1",
    Street2 = "street2",
    City = "city",
    Country = "country",
    Region = "region",
    PostalCode = "postalCode",
  }

  interface IKybVerificationOrder {
    field: KybVerificationFields;
    optional: boolean;
  }

  const KybVerificationOrder: IKybVerificationOrder[] = [
    {
      field: KybVerificationFields.Name,
      optional: false,
    },
    {
      field: KybVerificationFields.BusinessRegistrationNumber,
      optional: false,
    },
    {
      field: KybVerificationFields.Street1,
      optional: false,
    },
    {
      field: KybVerificationFields.Street2,
      optional: true,
    },
    {
      field: KybVerificationFields.City,
      optional: false,
    },
    {
      field: KybVerificationFields.Country,
      optional: false,
    },
    {
      field: KybVerificationFields.Region,
      optional: true,
    },
    {
      field: KybVerificationFields.PostalCode,
      optional: true,
    },
  ];

  const ErrorChildrenDataTree: DataNode[] = [];
  const VerifiedChildrenDataTree: DataNode[] = [];
  const ErrorDataTree: DataNode[] = [
    {
      title: (
        <Space direction="column" align="start" inline>
          <Text>Error</Text>
          <Text type="secondary">The following items were missing or impossible to validate</Text>
        </Space>
      ),
      key: "0-0",
      children: ErrorChildrenDataTree,
    },
  ];
  const VerifiedDataTree: DataNode[] = [
    {
      title: "Verified",
      key: "0-0",
      children: VerifiedChildrenDataTree,
    },
  ];

  const addVerifiedChildren = (fieldTitle: string, value: string | null, key: string, optional: boolean) => {
    VerifiedChildrenDataTree.push({
      title: (
        <Space direction="column" align="start" inline>
          <Text>{fieldTitle}</Text>
          <Text>{value ?? "Unable to verified"}</Text>
          <Text type="secondary">{optional ? "Optional" : "Required"}</Text>
        </Space>
      ),
      key,
      icon: <Icon type="circle-check" theme="solid" color={optional ? "grey" : "success"} />,
    });
  };

  const addErrorChildren = (fieldTitle: string, value: string | null, key: string) => {
    ErrorChildrenDataTree.push({
      title: (
        <Space direction="column" align="start" inline>
          <Text>{fieldTitle}</Text>
          <Text>{value ?? "Unable to verified"}</Text>
          <Text type="secondary">Required</Text>
        </Space>
      ),
      key,
      icon: <Icon type="circle-exclamation" theme="solid" color="warning" />,
    });
  };

  const addNode = (fieldTitle: string, value: string | null, key: string, optional: boolean) => {
    if (!!value) {
      addVerifiedChildren(fieldTitle, value, key, optional);
    } else if (!optional) {
      addErrorChildren(fieldTitle, value, key);
    }
  };

  KybVerificationOrder.forEach(({ field, optional }: IKybVerificationOrder, index: number) => {
    switch (field) {
      case KybVerificationFields.Name:
        addNode("Business Name", verifiedData.name, `0-0-${index}`, optional);
        break;
      case KybVerificationFields.BusinessRegistrationNumber:
        addNode("Business Registration Number", verifiedData.businessRegistrationNumber, `0-0-${index}`, optional);
        break;
      case KybVerificationFields.Street1:
        addNode("Street address 1", verifiedData.address.street1, `0-0-${index}`, optional);
        break;
      case KybVerificationFields.Street2:
        addNode("Street address 2", verifiedData.address.street2, `0-0-${index}`, optional);
        break;
      case KybVerificationFields.City:
        addNode("City", verifiedData.address.city, `0-0-${index}`, optional);
        break;
      case KybVerificationFields.Country:
        addNode(
          "Country",
          verifiedData.address.country ? formatCountry(verifiedData.address.country) : null,
          `0-0-${index}`,
          optional,
        );
        break;
      case KybVerificationFields.Region:
        addNode("Region", verifiedData.address.region, `0-0-${index}`, optional);
        break;
      case KybVerificationFields.PostalCode:
        addNode("Postal Code", verifiedData.address.postalCode, `0-0-${index}`, optional);
        break;
    }
  });

  return (
    <VerificationTree
      verifiedChildrenDataTree={VerifiedChildrenDataTree}
      errorChildrenDataTree={ErrorChildrenDataTree}
      verifiedDataTree={VerifiedDataTree}
      errorDataTree={ErrorDataTree}
    />
  );
}

function VerificationTree({
  verifiedChildrenDataTree,
  errorChildrenDataTree,
  verifiedDataTree,
  errorDataTree,
}: {
  verifiedChildrenDataTree: DataNode[];
  errorChildrenDataTree: DataNode[];
  verifiedDataTree: DataNode[];
  errorDataTree: DataNode[];
}) {
  const { isMobile } = useWindowSize();

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
      <Grid direction={isMobile ? "column" : "row"}>
        <Grid.Item xs={!isMobile && errorChildrenDataTree.length > 0 && verifiedChildrenDataTree.length > 0 ? 12 : 24}>
          <Tree
            showLine
            showIcon
            switcherIcon={<CaretDownOutlined rev={null} />}
            defaultExpandedKeys={["0-0-0"]}
            treeData={errorChildrenDataTree.length > 0 ? errorDataTree : verifiedDataTree}
          />
        </Grid.Item>
        {errorChildrenDataTree.length > 0 && verifiedChildrenDataTree.length > 0 && (
          <Grid.Item xs={isMobile ? 24 : 12}>
            <Tree
              showLine
              showIcon
              switcherIcon={<CaretDownOutlined rev={null} />}
              defaultExpandedKeys={["0-0-0"]}
              treeData={verifiedDataTree}
            />
          </Grid.Item>
        )}
      </Grid>
    </Container>
  );
}
