import { FTINType, getFtins } from "@trolley/common-frontend";
import React, { useMemo } from "react";

import { useIntl } from "utils/context";
import Select, { SelectOption, SelectProps } from "./Select";

interface Props extends Omit<SelectProps, "options"> {
  country: string | undefined | null;
  addOtherOption?: boolean;
  showEmptyOption?: boolean;
}

export default function SelectFTINType(props: Props) {
  const { formatMessage } = useIntl();
  const { country, addOtherOption, showEmptyOption = true, ...rest } = props;
  const options = useMemo(() => {
    const otherLabel = formatMessage({ id: "components.formTinTypes.other" });
    const fTinsByCountry = getFtins();
    const otherOption = { label: otherLabel, value: "other", "data-search": otherLabel, "aria-label": otherLabel };
    if (!country) {
      return [];
    }
    const fTins: FTINType = fTinsByCountry[country] ? fTinsByCountry[country] : [];

    return [
      ...Object.entries(fTins).map(
        ([code, fTinData]) =>
          ({
            label: fTinData?.name ?? "",
            value: code,
            "aria-label": fTinData?.name ?? "",
          } as SelectOption),
      ),
      ...(!!addOtherOption ? [otherOption] : []),
    ];
  }, [country]);

  return (
    <Select
      {...rest}
      placeholder={formatMessage({ id: "components.formTinTypes.select" })}
      options={options}
      allowClear={showEmptyOption}
      aria-label={formatMessage({ id: "components.formTinTypes.select" })}
    />
  );
}
