import {
  allMappedCountries,
  CountryCode,
  countryHasPostalCode,
  formatCountry,
  getPostalCodeProps,
  getRegionLabel,
  OrganizationType,
  UsUploadTaxFormType,
} from "@trolley/common-frontend";
import {
  Button,
  Container,
  Divider,
  Form,
  TinHelp,
  GreyBox,
  Grid,
  Icon,
  Modal,
  Paragraph,
  Title,
  Tooltip,
} from "components";
import { SelectFTINType, useForm } from "components/Form";
import { isRegionInCountryValidator } from "components/Form/SelectRegion";
import { PATHS } from "pages/App/routes";
import { getPostalLocaleId, getRegionLocaleId } from "pages/Info";
import { getIrsRule } from "pages/TaxSection/variables";
import { TIN_FORMAT } from "pages/TaxSection/W9/variables";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { notifySuccess } from "store/actions/notifications";
import { TaxDataW8BENE, TaxForm, updateTaxForm } from "store/actions/taxForms";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues, stringifyAddress } from "utils/helpers";
import CopyProfileAddress from "../CopyProfileAddress";
import CopyProfileName from "../CopyProfileName";
import TaxFooter from "../TaxFooter";
import { getOrganizationTypes } from "./variables";
import getFTinLabel from "utils/helpers/ftins";

interface Props {
  taxId: string;
  taxData: TaxDataW8BENE;
  loading?: boolean;
  pageIndex: number;
  pageTotal: number;
  onComplete(tf: TaxForm): void;
}

export default function Identification(props: Props) {
  const merchant = useMerchant();
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [form] = useForm();
  const { taxId, taxData, loading, pageIndex, pageTotal } = props;

  const [showWithholdingWarning, setShowWithholdingWarning] = useState(false);
  const [showCopyProfileName, setShowCopyProfileName] = useState<boolean | undefined>();
  const [showCopyRecipientAddress, setShowCopyRecipientAddress] = useState<boolean | undefined>(
    taxData.residenceAddress || !recipient?.address.country || recipient?.address.country === CountryCode.US
      ? false
      : undefined,
  );
  const [sameAddress, setSameAddress] = useState(
    taxData.residenceCountry &&
      taxData.residenceCountry === taxData.mailingCountry &&
      taxData.residenceRegion === taxData.mailingRegion &&
      taxData.residenceAddress === taxData.mailingAddress &&
      taxData.residenceCity === taxData.mailingCity &&
      taxData.residencePostalCode === taxData.mailingPostalCode,
  );

  const irsRule = getIrsRule(formatMessage);

  async function onFinish(values: Partial<TaxDataW8BENE>) {
    const withholdingWarningEnabled = !!taxData.noUsCertification && merchant?.tax?.displayW8WithholdingWarning;
    if (values.noTaxId && withholdingWarningEnabled && !showWithholdingWarning) {
      setShowWithholdingWarning(true);
    } else {
      setShowWithholdingWarning(false);
      const formattedData = {
        data: {
          ...omitMaskedValues(values),
          ftinType: values.ftinType !== "other" ? values.ftinType : null,
        },
      };
      if (sameAddress) {
        formattedData.data.mailingAddress = values.residenceAddress;
        formattedData.data.mailingCountry = values.residenceCountry;
        formattedData.data.mailingRegion = values.residenceRegion;
        formattedData.data.mailingCity = values.residenceCity;
        formattedData.data.mailingPostalCode = values.residencePostalCode;
      }
      try {
        const tf = await updateTaxForm(taxId, formattedData);
        props.onComplete(tf);
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    }
  }

  return (
    <Form
      form={form}
      initialValues={{
        ...taxData,
        ftinType: !taxData.ftinType && taxData.otherFtinDescription ? "other" : taxData.ftinType,
      }}
      onFinish={onFinish}
    >
      <Container>
        <Helmet>
          <title>
            {formatMessage({
              id: "containers.tax.w8.idOfBeneficialOwner",
            })}
          </title>
        </Helmet>
        <Title>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-8BEN-E" })}</Title>
        <Title level={2}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({
                id: "containers.tax.w8.idOfBeneficialOwner",
              }),
              step: pageIndex + 1,
              totalSteps: pageTotal,
            },
          )}
        </Title>
        <GreyBox>
          <Button
            type="link"
            href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(
              { id: "containers.tax.formInstructions" },
              {
                form: "W-8BEN-E",
              },
            )}
            <Icon type="external-link" right size="small" />
          </Button>
        </GreyBox>
        <Divider transparent size="small" />

        <Form.Field
          name="organizationName"
          label={formatMessage({ id: "containers.tax.w8.organizationName" })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: "containers.tax.w8.organizationNameRequired",
              }),
            },
            irsRule,
          ]}
        >
          {(control, meta, { setFieldsValue }) => (
            <>
              <Form.Input
                {...control}
                name="organizationName"
                onFocus={(e) => {
                  if (!e.target.value && showCopyProfileName === undefined && recipient?.name) {
                    setShowCopyProfileName(true);
                  }
                }}
              />

              <CopyProfileName
                label={formatMessage({
                  id: "containers.tax.w8.organizationName",
                })}
                name={recipient?.name}
                visible={!!showCopyProfileName}
                onClose={() => {
                  setShowCopyProfileName(false);
                }}
                onOk={() => {
                  setFieldsValue({
                    organizationName: recipient?.name,
                  });
                  setShowCopyProfileName(false);
                  notifySuccess(formatMessage({ id: "containers.tax.nameCopied" }));
                }}
              />
            </>
          )}
        </Form.Field>

        <Grid>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="organizationCountry"
              label={formatMessage({
                id: "containers.tax.w8.countryOfIncorporation",
              })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.country.required",
                  }),
                },
              ]}
            >
              <Form.SelectCountry type="all" name="organizationCountry" />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="organizationType"
              label={formatMessage({ id: "containers.tax.w8.entityType" })}
              rules={[{ required: true, message: "Type is required" }]}
            >
              <Form.Select name="organizationType" options={getOrganizationTypes(formatMessage)} />
            </Form.Field>
          </Grid.Item>

          <Form.Control dependencies={["organizationType"]}>
            {({ value: { organizationType } }) => {
              return (
                [
                  OrganizationType.PARTNERSHIP,
                  OrganizationType.DISREGARDED_ENTITY,
                  OrganizationType.SIMPLE_TRUST,
                  OrganizationType.GRANTOR_TRUST,
                ].includes(organizationType) && (
                  <Grid.Item xs={24} sm={12}>
                    <Form.Field
                      name="isOrganizationHybrid"
                      label={formatMessage({
                        id: "containers.tax.w8.isHybrid",
                      })}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: "containers.tax.w8.isHybridRequired",
                          }),
                        },
                      ]}
                      // normalize={(v) => v === "true"} // save as string. not boolean
                    >
                      <Form.Radio
                        name="isOrganizationHybrid"
                        options={[
                          {
                            label: formatMessage({
                              id: "common.no",
                            }),
                            value: "false",
                          },
                          {
                            label: formatMessage({
                              id: "common.yes",
                            }),
                            value: "true",
                          },
                        ]}
                      />
                    </Form.Field>
                  </Grid.Item>
                )
              );
            }}
          </Form.Control>
        </Grid>

        <Divider />
        <Title level={2}>{formatMessage({ id: "containers.tax.w8.permanentResidence" })}</Title>
        <Grid>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="residenceAddress"
              label="Address"
              rules={[{ required: true, message: "Address is required" }, irsRule]}
            >
              {(control, meta, { setFieldsValue }) => (
                <>
                  <Form.Input
                    {...control}
                    name="residenceAddress"
                    onFocus={(e) => {
                      if (!e.target.value && showCopyRecipientAddress === undefined && recipient?.address?.country) {
                        setShowCopyRecipientAddress(true);
                      }
                    }}
                  />
                  <CopyProfileAddress
                    label="Residence Address"
                    address={[
                      [recipient?.address.street1, recipient?.address.street2].filter((v) => v).join(" "),
                      stringifyAddress({
                        city: recipient?.address.city,
                        region: getRegionLabel(recipient?.address.region, recipient?.address.country),
                        postalCode: recipient?.address.postalCode,
                      }),
                      formatCountry(recipient?.address.country, formatMessage),
                    ]}
                    visible={!!showCopyRecipientAddress}
                    onClose={() => {
                      setShowCopyRecipientAddress(false);
                    }}
                    onOk={() => {
                      if (recipient) {
                        setFieldsValue({
                          residenceAddress: [recipient?.address.street1, recipient?.address.street2]
                            .filter((v) => v)
                            .join(" "),
                          residenceCity: recipient?.address.city,
                          residenceRegion: recipient?.address.region,
                          residencePostalCode: recipient?.address.postalCode,
                          residenceCountry: recipient?.address.country,
                        });
                        notifySuccess("Address information copied");
                      }
                      setShowCopyRecipientAddress(false);
                    }}
                  />
                </>
              )}
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="residenceCity"
              label="City"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.city.required",
                  }),
                },
                irsRule,
              ]}
            >
              <Form.Input name="residenceCity" />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="residenceCountry"
              label={formatMessage({
                id: "containers.tax.w8.countryOfResidence",
              })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.country.required",
                  }),
                },
                {
                  async validator(rule, value) {
                    if (value === CountryCode.US) {
                      throw formatMessage({ id: "common.invalidValue" });
                    }
                  },
                },
              ]}
            >
              <Form.SelectCountry
                type="all"
                name="residenceCountry"
                excludes={[CountryCode.US]} // person cannot be a US permanent resident, but can be a resident of a US territory. Person can also have US mailing address
                onChange={() => {
                  form.setFieldsValue({
                    residenceRegion: undefined,
                    ftinType: undefined,
                    otherFtinDescription: "",
                    taxPayerForeignId: "",
                  });
                }}
              />
            </Form.Field>
          </Grid.Item>

          <Form.Control dependencies={["residenceCountry"]}>
            {({ value: { residenceCountry } }) => {
              const residenceCountryHasRegions =
                residenceCountry && !!allMappedCountries[residenceCountry]?.regions.length;
              const postalCodeProps = getPostalCodeProps(residenceCountry, formatMessage);

              return (
                <>
                  {residenceCountryHasRegions && (
                    <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                      <Form.Field
                        name="residenceRegion"
                        key={residenceCountry}
                        label={formatMessage({
                          id: getRegionLocaleId(residenceCountry, "title"),
                          defaultMessage: "Region / Province",
                        })}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: getRegionLocaleId(residenceCountry, "required"),
                              defaultMessage: "Region / Province is required",
                            }),
                          },
                          isRegionInCountryValidator("residenceCountry", formatMessage),
                        ]}
                      >
                        <Form.SelectRegion name="residenceRegion" country={residenceCountry} />
                      </Form.Field>
                    </Grid.Item>
                  )}

                  {postalCodeProps.hasPostalCode && (
                    <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                      <Form.Field
                        name="residencePostalCode"
                        label={formatMessage({
                          id: getPostalLocaleId(residenceCountry, "title"),
                        })}
                        hint={postalCodeProps.hint}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: getPostalLocaleId(residenceCountry, "required"),
                            }),
                          },
                          irsRule,
                        ]}
                      >
                        <Form.Input name="residencePostalCode" />
                      </Form.Field>
                    </Grid.Item>
                  )}
                </>
              );
            }}
          </Form.Control>
        </Grid>

        <Divider />
        <Title level={2}>{formatMessage({ id: "containers.tax.mailingAddress" })}</Title>

        <Form.Checkbox
          name="copyResidence"
          checked={sameAddress}
          onChange={(checked) => {
            if (!checked) {
              form.setFieldsValue({
                mailingCountry: undefined,
                mailingRegion: undefined,
                mailingAddress: undefined,
                mailingCity: undefined,
                mailingPostalCode: undefined,
              });
            }
            setSameAddress(checked);
          }}
        >
          {formatMessage({ id: "containers.tax.w8.sameAsResidence" })}
        </Form.Checkbox>
        <Grid>
          {sameAddress ? (
            <>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingAddress"
                  label={formatMessage({ id: "containers.info.street1.title" })}
                  key="same-mail-add"
                  dependencies={["residenceAddress"]}
                >
                  {(_, meta, { getFieldValue }) => <Form.Input disabled value={getFieldValue("residenceAddress")} />}
                </Form.Field>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCity"
                  label={formatMessage({ id: "containers.info.city.title" })}
                  key="same-mail-city"
                  dependencies={["residenceCity"]}
                >
                  {(_, meta, { getFieldValue }) => <Form.Input disabled value={getFieldValue("residenceCity")} />}
                </Form.Field>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCountry"
                  label={formatMessage({ id: "containers.info.country.title" })}
                  key="same-mail-country"
                  dependencies={["residenceCountry"]}
                >
                  {(_, meta, { getFieldValue }) => (
                    //  W8 cannot be a US permanent resident. but can have US mailing address
                    <Form.SelectCountry disabled type="all" value={getFieldValue("residenceCountry")} />
                  )}
                </Form.Field>
              </Grid.Item>

              <Form.Control dependencies={["residenceCountry"]}>
                {({ value: { residenceCountry } }) => {
                  const residenceCountryHasRegions =
                    residenceCountry && !!allMappedCountries[residenceCountry]?.regions.length;
                  const residenceCountryHasPostalCode = countryHasPostalCode(residenceCountry);

                  return (
                    <>
                      {residenceCountryHasRegions && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                          <Form.Field
                            name="mailingRegion"
                            key={residenceCountry}
                            label={formatMessage({
                              id: getRegionLocaleId(residenceCountry, "title"),
                              defaultMessage: "State / Province",
                            })}
                            dependencies={["residenceRegion"]}
                          >
                            {(_, meta, { getFieldValue }) => (
                              <Form.SelectRegion
                                disabled
                                country={residenceCountry}
                                value={getFieldValue("residenceRegion")}
                              />
                            )}
                          </Form.Field>
                        </Grid.Item>
                      )}
                      {residenceCountryHasPostalCode && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                          <Form.Field
                            name="mailingPostalCode"
                            label={formatMessage({
                              id: getPostalLocaleId(residenceCountry, "title"),
                            })}
                            dependencies={["residencePostalCode"]}
                          >
                            {(_, meta, { getFieldValue }) => (
                              <Form.Input disabled value={getFieldValue("residencePostalCode")} />
                            )}
                          </Form.Field>
                        </Grid.Item>
                      )}
                    </>
                  );
                }}
              </Form.Control>
            </>
          ) : (
            <>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingAddress"
                  label={formatMessage({ id: "containers.info.street1.title" })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.street1.required",
                      }),
                    },
                    irsRule,
                  ]}
                >
                  <Form.Input name="mailingAddress" />
                </Form.Field>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCity"
                  label={formatMessage({ id: "containers.info.city.title" })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.city.required",
                      }),
                    },
                    irsRule,
                  ]}
                >
                  <Form.Input name="mailingCity" />
                </Form.Field>
              </Grid.Item>

              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCountry"
                  label={formatMessage({ id: "containers.info.country.title" })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.country.required",
                      }),
                    },
                  ]}
                >
                  <Form.SelectCountry
                    // W8 cannot be a US permanent resident. but can have US mailing address
                    type="all"
                    name="mailingCountry"
                    onChange={() => {
                      form.setFieldsValue({ mailingRegion: undefined });
                    }}
                  />
                </Form.Field>
              </Grid.Item>

              <Form.Control dependencies={["mailingCountry"]}>
                {({ value: { mailingCountry } }) => {
                  const mailingCountryHasRegions =
                    mailingCountry && !!allMappedCountries[mailingCountry]?.regions.length;
                  const postalCodeProps = getPostalCodeProps(mailingCountry, formatMessage);

                  return (
                    <>
                      {mailingCountryHasRegions && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                          <Form.Field
                            name="mailingRegion"
                            key={mailingCountry}
                            label={formatMessage({
                              id: getRegionLocaleId(mailingCountry, "title"),
                              defaultMessage: "State / Province",
                            })}
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: getRegionLocaleId(mailingCountry, "required"),
                                  defaultMessage: "State / Province is required",
                                }),
                              },
                              isRegionInCountryValidator("mailingCountry", formatMessage),
                            ]}
                          >
                            <Form.SelectRegion name="mailingRegion" country={mailingCountry} />
                          </Form.Field>
                        </Grid.Item>
                      )}
                      {postalCodeProps.hasPostalCode && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                          <Form.Field
                            name="mailingPostalCode"
                            label={formatMessage({
                              id: getPostalLocaleId(mailingCountry, "title"),
                            })}
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: getPostalLocaleId(mailingCountry, "required"),
                                }),
                              },
                              irsRule,
                            ]}
                            hint={postalCodeProps.hint}
                          >
                            <Form.Input name="mailingPostalCode" />
                          </Form.Field>
                        </Grid.Item>
                      )}
                    </>
                  );
                }}
              </Form.Control>
            </>
          )}
        </Grid>

        <Divider />
        <Title level={2}>{formatMessage({ id: "containers.tax.w8.taxPayerId" })}</Title>

        <Paragraph>
          {formatMessage(
            { id: "containers.tax.w8.provideFTIN" },
            {
              tooltip: (c) => (
                <Tooltip
                  title={formatMessage({
                    id: "containers.tax.w8.usResidentInfo",
                  })}
                >
                  <dfn
                    title={formatMessage({
                      id: "containers.tax.w8.usResidentInfo",
                    })}
                  >
                    {c}
                    <Icon type="question-circle" />
                  </dfn>
                </Tooltip>
              ),
            },
          )}
        </Paragraph>

        <Grid>
          <Form.Control
            dependencies={[
              "noTaxId",
              "taxPayerUsId",
              "taxPayerForeignId",
              "residenceCountry",
              "ftinType",
              "otherFtinDescription",
            ]}
          >
            {({
              value: { noTaxId, taxPayerUsId, taxPayerForeignId, residenceCountry, ftinType, otherFtinDescription },
            }) => {
              return (
                <Grid.Item xs={24} sm={12}>
                  <Grid direction="column">
                    <Grid.Item>
                      <Form.Field
                        name="ftinType"
                        label={formatMessage({ id: "containers.tax.w8.fTinType" })}
                        rules={[
                          {
                            required: !noTaxId && !taxPayerUsId && residenceCountry,
                            message: formatMessage({ id: "containers.tax.w8.entityTypeRequired" }),
                          },
                        ]}
                      >
                        <SelectFTINType
                          name="ftinType"
                          disabled={noTaxId || !residenceCountry}
                          country={residenceCountry}
                          addOtherOption
                          showEmptyOption
                          style={{ display: "block" }}
                        />
                      </Form.Field>
                    </Grid.Item>
                    {ftinType !== "other" && (
                      <Grid.Item>
                        <>
                          <TinHelp country={residenceCountry} ftinType={ftinType} />
                          <Divider transparent />
                        </>
                      </Grid.Item>
                    )}
                    {ftinType === "other" && (
                      <Grid.Item>
                        <Form.Field
                          name="otherFtinDescription"
                          label={formatMessage({ id: "containers.tax.w8.otherTinTypeDescription" })}
                          tooltip={formatMessage({ id: "containers.tax.w8.otherTinTypeDescriptionTooltip" })}
                          rules={[
                            {
                              required: !noTaxId && !taxPayerUsId && residenceCountry && ftinType === "other",
                              message: formatMessage({ id: "containers.tax.w8.entityTypeRequired" }),
                            },
                          ]}
                        >
                          <Form.Input name="otherFtinDescription" disabled={noTaxId || !residenceCountry} />
                        </Form.Field>
                      </Grid.Item>
                    )}
                    <Grid.Item>
                      <Form.Field
                        name="taxPayerForeignId"
                        label={
                          residenceCountry && ftinType && ftinType !== "other"
                            ? getFTinLabel(residenceCountry, ftinType)
                            : formatMessage({ id: "containers.tax.fTin" })
                        }
                        rules={
                          noTaxId
                            ? []
                            : [
                                {
                                  required: !taxPayerUsId,
                                  message: formatMessage({ id: "containers.tax.w8.fTinRequired" }, { tinName: "EIN" }),
                                },
                                {
                                  async validator(_: any, value: string) {
                                    if (value && !value.includes("*") && !irsRule.pattern.test(value)) {
                                      throw new Error(irsRule.message);
                                    }
                                  },
                                },
                              ]
                        }
                      >
                        <Form.Input
                          name="taxPayerForeignId"
                          disabled={
                            noTaxId || !residenceCountry || !ftinType || (ftinType === "other" && !otherFtinDescription)
                          }
                        />
                      </Form.Field>
                    </Grid.Item>

                    <Grid.Item>
                      <Form.Field
                        name="taxPayerUsId"
                        label={`${formatMessage({ id: "containers.tax.usTin" })} (EIN)`}
                        optional
                        rules={
                          noTaxId
                            ? []
                            : [
                                {
                                  async validator(_: any, value: string) {
                                    if (value && !value.includes("*") && value.replace(/\D/g, "").length < 9) {
                                      throw new Error(
                                        formatMessage({ id: "containers.tax.tinValidation" }, { tinName: "EIN" }),
                                      );
                                    }
                                  },
                                },
                              ]
                        }
                      >
                        <Form.InputMask name="taxPayerUsId" disabled={noTaxId} mask={TIN_FORMAT.tin} />
                      </Form.Field>
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
              );
            }}
          </Form.Control>

          <Grid.Item xs={24}>
            <Form.Field name="noTaxId">
              <Form.Checkbox
                name="noTaxId"
                onChange={(checked) => {
                  if (checked) {
                    form.setFieldsValue({
                      taxPayerUsId: "",
                      taxPayerForeignId: "",
                    });
                  } else {
                    form.setFieldsValue({
                      taxPayerUsId: taxData?.taxPayerUsId || "",
                      taxPayerForeignId: taxData?.taxPayerForeignId || "",
                    });
                  }
                }}
              >
                {formatMessage({ id: "containers.tax.w8.willNotProvideTin" })}
              </Form.Checkbox>
            </Form.Field>
          </Grid.Item>
        </Grid>
        <TaxFooter loading={loading} />
      </Container>

      {/* NO TAX ID WITHHOLDING WARNING */}
      <Modal
        open={showWithholdingWarning}
        onCancel={() => {
          setShowWithholdingWarning(false);
        }}
        onOk={form.submit}
        okText={formatMessage({ id: "common.continue" })}
        title={formatMessage({
          id: "containers.tax.subjectToWithholdingTitle",
        })}
      >
        <GreyBox>
          <b>{formatMessage({ id: "containers.tax.subjectToWithholding" })}</b>
        </GreyBox>
      </Modal>

      {/* Hybrid Organization */}
      <Form.Control<TaxDataW8BENE>
        shouldUpdate={(prev, next) => prev.isOrganizationHybrid !== next.isOrganizationHybrid}
      >
        {({ value: { isOrganizationHybrid } }) => (
          <Modal
            open={isOrganizationHybrid === "true"}
            onCancel={() => {
              form.setFieldsValue({ isOrganizationHybrid: "false" });
            }}
            onOk={() => {
              history.push(`${PATHS.US_TAX_UPLOAD}`, {
                formType: UsUploadTaxFormType.W8IMY,
              });
            }}
            okText={formatMessage({ id: "containers.tax.w8.uploadW8IMY" })}
            title={formatMessage({ id: "containers.tax.w8.W8IMYRequired" })}
          >
            <GreyBox>
              <Paragraph>{formatMessage({ id: "containers.tax.w8.needToCompleteW8IMY" })}</Paragraph>
              <Button
                type="link"
                href="https://www.irs.gov/pub/irs-pdf/fw8imy.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.irs.gov/pub/irs-pdf/fw8imy.pdf
              </Button>
              <Divider transparent />
              <Paragraph>{formatMessage({ id: "containers.tax.w8.formIsCorrect" })}</Paragraph>
            </GreyBox>
          </Modal>
        )}
      </Form.Control>
    </Form>
  );
}
