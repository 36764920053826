import * as types from "@trolley/common-frontend";
import { RecursivePartial } from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { isLoaded } from "./actionUtils";
import { Mapped } from "store/reducers/standardReducer";
import { emitEvent, WidgetEvent } from "utils/helpers";
import { PRODUCT_MODULES } from "utils/context";

export interface TaxProfile extends Omit<types.TaxProfile, "data"> {
  data: Partial<types.TaxProfileData>;
}

export function loadTaxProfile(taxProfileId: string, force?: true) {
  const {
    taxProfile,
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  const recipientId = recipient?.id ?? "";
  const id = taxProfileId;
  if (recipientId && (force || !isLoaded(taxProfile.fetchStatus[id]))) {
    standardDispatch(OpCode.LOADING, "taxProfile", { id });
    request
      .POST<types.TaxProfileListResponse>("/v1/tax-profile/search", {
        query: { recipientId, ids: [taxProfileId] },
      })
      .then(({ body: { taxProfiles } }) => {
        standardDispatch(OpCode.DATA, "taxProfile", {
          id,
          data: taxProfiles[0],
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxProfile", { id, errors });
      });
  }
}

export function loadTaxProfiles(force?: true) {
  const {
    taxProfiles,
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  const recipientId = recipient?.id ?? "";
  const id = "data";
  if (recipientId && (force || !isLoaded(taxProfiles.fetchStatus[id]))) {
    standardDispatch(OpCode.LOADING, "taxProfiles", { id });
    request
      .POST<types.TaxProfileListResponse>("/v1/tax-profile/search", {
        query: { recipientId },
      })
      .then(({ body: { taxProfiles, meta } }) => {
        standardDispatch(OpCode.DATA, "taxProfiles", {
          id,
          data: {
            records: taxProfiles.map((tp) => tp.id),
            meta,
          },
        });

        standardDispatch(OpCode.DATA, "taxProfile", {
          bulk: taxProfiles.reduce((acc, tp) => {
            acc[tp.id] = tp;

            return acc;
          }, {} as Mapped<TaxProfile>),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxProfiles", { id, errors });
      });
  }
}

export interface TaxProfileCreate {
  recipientId: types.RecipientGUID;
}

export async function createTaxProfile(data: TaxProfileCreate) {
  try {
    standardDispatch(OpCode.LOADING, "taxProfile");
    const {
      body: { taxProfile },
    } = await request.POST<types.TaxProfileResponse>("/v1/tax-profile/create", { query: data });
    batch(async () => {
      standardDispatch(OpCode.DATA, "taxProfile", {
        id: taxProfile.id,
        data: taxProfile,
      });
      loadTaxProfiles(true);
    });

    return taxProfile;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", { errors });
    throw errors;
  }
}

export async function updateTaxProfile(taxProfileId: string, data: RecursivePartial<TaxProfile>) {
  try {
    standardDispatch(OpCode.LOADING, "taxProfile", { id: taxProfileId });
    const {
      body: { taxProfile },
    } = await request.POST<types.TaxProfileResponse>("/v1/tax-profile/update", {
      query: data,
    });

    standardDispatch(OpCode.DATA, "taxProfile", {
      id: taxProfileId,
      data: taxProfile,
    });

    return taxProfile;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", {
      id: taxProfileId,
      errors,
    });
    throw errors;
  }
}

export async function signAndSubmitTaxProfile(taxProfileId: string, name: string) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  try {
    standardDispatch(OpCode.LOADING, "taxProfile", { id: taxProfileId });
    await request.POST<{}>("/v1/tax-profile/sign", {
      query: {
        id: taxProfileId,
        recipientId: recipient?.id,
        signature: name,
      },
    });
    emitEvent({ event: WidgetEvent.TAXPROFILE_SUBMITTED, taxProfile: { id: taxProfileId } });
    // Standard extra dispatch to let them know the state of the product itself
    emitEvent({
      event: WidgetEvent.MODULE_SUCCESSFUL,
      module: [PRODUCT_MODULES.TAX],
    });
    standardDispatch(OpCode.LOADING, "taxProfile", { loading: false, id: taxProfileId });
    loadTaxProfiles(true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", { id: taxProfileId, errors });
    emitEvent({
      event: WidgetEvent.MODULE_FAILED,
      module: [PRODUCT_MODULES.TAX],
    });
    throw errors;
  }
}
