import { Button, Icon, LinkButton, Modal, Space, Status, Typography } from "components";
import { PATHS } from "pages/App/routes";
import React, { useState } from "react";
import css, { createUseStyle, useTheme } from "style/classname";
import { useClientIntegration, useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";

export enum WizardStatus {
  NOT_STARTED = "notStarted",
  IN_PROGRESS = "inProgress",
  DONE = "done",
}

interface WizardHeaderProps {
  status: WizardStatus;
  onSaveAndQuit?: (save: boolean) => void;
  showBackButton?: boolean;
  onBackClick?: () => void;
}

export default function WizardHeader(props: WizardHeaderProps) {
  const { status, onSaveAndQuit, showBackButton = false, onBackClick } = props;
  const { formatMessage } = useIntl();
  const token = useTheme();
  const client = useClientIntegration();
  const { isMobile } = useWindowSize();
  const styledSpace = useStyledSpace({ ...props, isMobile });
  const styledStatus = useStyledStatus({ ...props, isMobile });
  const [cancelVisible, setCancelVisible] = useState(false);

  function onCancelInProgress() {
    setCancelVisible(false);
    if (onSaveAndQuit) {
      onSaveAndQuit(status === WizardStatus.IN_PROGRESS);
    }
  }

  return (
    <>
      <Space className={styledSpace} align="center">
        {showBackButton && (
          <Button
            onClick={onBackClick}
            aria-label={formatMessage({ id: "common.back" })}
            type="text"
            icon="arrow-left"
            shape="circle"
            size="middle"
            style={{ backgroundColor: token.grey1 }}
          />
        )}
        <Space direction={isMobile ? "column" : "row"} align={isMobile ? "center" : "baseline"}>
          <Typography.Title level={2} padded={false}>
            {client === "portal"
              ? formatMessage({ id: "containers.taxProfile.wizard.headerTitlePortal" })
              : formatMessage({ id: "containers.taxProfile.wizard.headerTitleWidget" })}
          </Typography.Title>
          {status !== WizardStatus.NOT_STARTED && (
            <Status
              type={status === WizardStatus.DONE ? "success" : "warning"}
              className={styledStatus}
              style={{
                padding: isMobile ? "0 8px 0 5px" : "1px 8px 1px 5px",
              }}
              prefix={
                status === WizardStatus.DONE && (
                  <Icon type="circle-check" theme="solid" color="success" style={{ marginRight: "2px" }} />
                )
              }
            >
              {status === WizardStatus.DONE
                ? formatMessage({ id: "common.done" })
                : formatMessage({ id: "common.inProgress" })}
            </Status>
          )}
        </Space>

        {client === "portal" && status === WizardStatus.DONE && (
          <LinkButton
            to={PATHS.HOME}
            type="text"
            icon={isMobile ? "xmark-large" : "xmark"}
            shape="circle"
            size="middle"
            style={{ backgroundColor: token.grey1 }}
            aria-label={formatMessage({ id: "common.exit" })}
          />
        )}
        {client === "widget" && status === WizardStatus.DONE && (
          <LinkButton to={PATHS.HOME} type="default">
            {formatMessage({ id: "common.exit" })}
          </LinkButton>
        )}
        {client === "portal" && status !== WizardStatus.DONE && (
          <Button
            onClick={() => {
              setCancelVisible(true);
            }}
            type="text"
            icon={isMobile ? "xmark-large" : "xmark"}
            shape="circle"
            size="middle"
            aria-label={formatMessage({ id: "common.exit" })}
            style={{ backgroundColor: token.grey1 }}
          />
        )}
        {client === "widget" && status !== WizardStatus.DONE && (
          <Button
            onClick={() => {
              setCancelVisible(true);
            }}
            type="default"
          >
            {formatMessage({ id: "common.exit" })}
          </Button>
        )}
      </Space>
      {status !== WizardStatus.DONE && (
        <Modal
          title={formatMessage({ id: "containers.taxProfile.wizard.headerConfirmExitTitle" })}
          cancelText={formatMessage({ id: "common.cancel" })}
          okText={formatMessage({ id: "containers.taxProfile.wizard.headerConfirmExitYes" })}
          onCancel={() => {
            setCancelVisible(false);
          }}
          onOk={onCancelInProgress}
          open={cancelVisible}
        >
          <>
            <Typography.Title level={3}>
              {formatMessage({ id: "containers.taxProfile.wizard.headerConfirmExitQuestion" })}
            </Typography.Title>
            {status === WizardStatus.IN_PROGRESS && (
              <Typography.Text>
                {formatMessage({ id: "containers.taxProfile.wizard.headerConfirmExitProgressSaved" })}
              </Typography.Text>
            )}
          </>
        </Modal>
      )}
    </>
  );
}

const useStyledSpace = createUseStyle<WizardHeaderProps & { isMobile: boolean }>(({ theme, isMobile, ...props }) =>
  css`
    height: 100%;
    ${isMobile &&
    `
        & > div:nth-of-type(${props.showBackButton ? 2 : 1}) {
          flex: 1;
      }
    `}
    & > div:first-of-type {
      margin-right: auto;
    }
  `(),
);

const useStyledStatus = createUseStyle<WizardHeaderProps & { isMobile: boolean }>(({ theme, isMobile, ...props }) =>
  css`
    ${isMobile && "margin-left: 0;"}
    text-transform: none;
    ${() => {
      switch (props.status) {
        case "inProgress":
          return `
        background: #fff1ce;
        color: #654d10;
        `;
        case "done":
          return `
        background: #cce2d8;
        color: #005a30;
        `;
        default:
          return ``;
      }
    }}
  `(),
);
