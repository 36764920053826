/* istanbul ignore file */
import * as types from "@trolley/common-frontend";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Query } from "utils/helpers";
import { computeID } from "./actionUtils";

export type BankInfo = types.LookupData["lookupData"];

export interface BankParameters {
  country?: string | null;
  currency?: string | null;
  iban?: string | null;
  swiftBic?: string | null;
  bankId?: string | null;
  branchId?: string | null;
}

export interface BankQuery extends Query, BankParameters {}

export function loadBankInfo(query: BankQuery) {
  const { bankInfo } = store.getState();
  const id = computeID(query);

  if (bankInfo.fetchStatus[id] === undefined) {
    standardDispatch(OpCode.LOADING, "bankInfo", { id });
    request
      .POST<types.BankInfo.Result>("/v1/bank/lookup", { query })
      .then((res) => {
        standardDispatch(OpCode.DATA, "bankInfo", {
          id,
          data: res.body.lookupData,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "bankInfo", { id, errors });
      });
  }

  return id;
}
