import {
  CountryCode,
  allMappedCountries,
  countryHasPostalCode,
  formatCountry,
  getPostalCodeProps,
  getRegionLabel,
} from "@trolley/common-frontend";
import React, { useState } from "react";
import Helmet from "react-helmet";

import {
  Button,
  Container,
  Divider,
  TinHelp,
  Form,
  GreyBox,
  Grid,
  Icon,
  Paragraph,
  Text,
  Title,
  Tooltip,
} from "components";
import { isLegalAgeDateValidator } from "components/Form/DatePicker";
import { isRegionInCountryValidator } from "components/Form/SelectRegion";
import useModal from "components/Modal/useModal";
import { getPostalLocaleId, getRegionLocaleId } from "pages/Info";
import TreatyTable from "pages/TaxSection/TreatyTable";
import { TIN_FORMAT } from "pages/TaxSection/W9/variables";
import { getIrsRule } from "pages/TaxSection/variables";
import { notifySuccess } from "store/actions/notifications";
import { TaxDataW8BEN, TaxForm, updateTaxForm } from "store/actions/taxForms";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useWithholdingTable } from "store/hooks/withholdingTable";
import { useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues, stringifyAddress } from "utils/helpers";
import CopyProfileAddress from "../CopyProfileAddress";
import CopyProfileName from "../CopyProfileName";
import TaxFooter from "../TaxFooter";
import { SelectFTINType } from "components/Form";
import getFTinLabel from "utils/helpers/ftins";

interface Props {
  taxId: string;
  taxData: TaxDataW8BEN;
  calculatedWithholdings: TaxForm["calculatedWithholdings"];
  loading?: boolean;
  onComplete(): void;
}

export default function Identification(props: Props) {
  const merchant = useMerchant();
  const recipient = useRecipient();
  const modal = useModal();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { taxId, taxData, loading } = props;
  const { data: withholdingTable } = useWithholdingTable();
  const residenceCountry = Form.useWatch("residenceCountry", form);
  const [showCopyProfileName, setShowCopyProfileName] = useState<boolean | undefined>(
    taxData.firstName ? false : undefined,
  );
  const [showCopyRecipientAddress, setShowCopyRecipientAddress] = useState<boolean | undefined>(
    taxData.residenceAddress || !recipient?.address?.country || recipient.address.country === CountryCode.US
      ? false
      : undefined,
  );
  const [sameAddress, setSameAddress] = useState(
    taxData.residenceCountry &&
      taxData.residenceCountry === taxData.mailingCountry &&
      taxData.residenceRegion === taxData.mailingRegion &&
      taxData.residenceAddress === taxData.mailingAddress &&
      taxData.residenceCity === taxData.mailingCity &&
      taxData.residencePostalCode === taxData.mailingPostalCode,
  );
  const irsRule = getIrsRule(formatMessage);

  function onFinish(values: Partial<TaxDataW8BEN>) {
    const withholdingWarningEnabled = !!taxData.noUsCertification && merchant?.tax?.displayW8WithholdingWarning;
    if (values.noTaxId && withholdingWarningEnabled) {
      /* NO TAX ID WITHHOLDING WARNING */
      modal.confirm({
        title: formatMessage({
          id: "containers.tax.subjectToWithholdingTitle",
        }),
        content: (
          <GreyBox>
            <b>{formatMessage({ id: "containers.tax.subjectToWithholding" })}</b>
          </GreyBox>
        ),
        okText: formatMessage({ id: "common.continue" }),
        onOk: onUpdate,
      });
    } else {
      onUpdate();
    }
  }

  function onUpdate() {
    const values = form.getFieldsValue();

    if (form.isFieldsTouched()) {
      const formattedData = {
        data: {
          ...omitMaskedValues(values),
          ftinType: values.ftinType !== "other" ? values.ftinType : null,
        },
      };
      if (sameAddress) {
        formattedData.data.mailingAddress = values.residenceAddress;
        formattedData.data.mailingCountry = values.residenceCountry;
        formattedData.data.mailingRegion = values.residenceRegion;
        formattedData.data.mailingCity = values.residenceCity;
        formattedData.data.mailingPostalCode = values.residencePostalCode;
      }

      updateTaxForm(taxId, formattedData)
        .then((taxFormData: TaxForm) => {
          onFinalize(residenceCountry, taxFormData.calculatedWithholdings);
        })
        .catch((errors) => {
          handleFormErrors(errors, form);
        });
    } else {
      onFinalize(residenceCountry, props.calculatedWithholdings);
    }
  }

  function onFinalize(residenceCountry: CountryCode, calculatedWithholdings?: TaxForm["calculatedWithholdings"]) {
    if (calculatedWithholdings && residenceCountry && withholdingTable[residenceCountry]) {
      // if there is treaty between for residence country
      modal.info({
        onOk: props.onComplete,
        okText: formatMessage({ id: "common.continue" }),
        width: 600,
        title: formatMessage({
          id: "containers.tax.w8.claimOfTreatyBenefits",
        }),
        content: (
          <>
            {residenceCountry && (
              <Text>
                {formatMessage(
                  { id: "containers.tax.w8.specialRates" },
                  {
                    country: (
                      <Text strong inline>
                        {formatCountry(residenceCountry, formatMessage)}
                      </Text>
                    ),
                  },
                )}
              </Text>
            )}
            <Divider transparent />
            <GreyBox>
              <TreatyTable country={residenceCountry} calculatedWithholdings={calculatedWithholdings} />
            </GreyBox>
          </>
        ),
      });
    } else {
      props.onComplete();
    }
  }

  return (
    <Form
      form={form}
      initialValues={{
        ...taxData,
        ftinType: !taxData.ftinType && taxData.otherFtinDescription ? "other" : taxData.ftinType,
      }}
      onFinish={onFinish}
    >
      <Container>
        <Helmet>
          <title>
            {formatMessage({
              id: "containers.tax.w8.idOfBeneficialOwner",
            })}
          </title>
        </Helmet>

        <Title>{formatMessage({ id: "containers.tax.substituteForm" }, { form: "W-8BEN" })}</Title>
        <Title level={2}>
          {formatMessage(
            { id: "containers.tax.sectionTitle" },
            {
              title: formatMessage({
                id: "containers.tax.w8.idOfBeneficialOwner",
              }),
              step: 2,
              totalSteps: 4,
            },
          )}
        </Title>
        <GreyBox>
          <Button
            type="link"
            href="https://www.irs.gov/pub/irs-pdf/iw8ben.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(
              { id: "containers.tax.formInstructions" },
              {
                form: "W-8BEN",
              },
            )}
            <Icon type="external-link" right size="small" />
          </Button>
        </GreyBox>
        <Divider transparent size="small" />
        <Grid>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="firstName"
              label={formatMessage({ id: "containers.tax.w8.firstName" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.tax.w8.firstNameRequired",
                  }),
                },
                irsRule,
              ]}
            >
              {(control, meta, { setFieldsValue }) => (
                <>
                  <Form.Input
                    {...control}
                    name="firstName"
                    onFocus={(e) => {
                      if (!e.target.value && showCopyProfileName === undefined) {
                        setShowCopyProfileName(true);
                      }
                    }}
                  />

                  <CopyProfileName
                    label={formatMessage({
                      id: "containers.tax.w8.nameOfIndividual",
                    })}
                    name={[recipient?.firstName, recipient?.lastName]
                      .filter((v) => v)
                      .join(" ")
                      .trim()}
                    visible={!!showCopyProfileName}
                    onClose={() => {
                      setShowCopyProfileName(false);
                    }}
                    onOk={() => {
                      setFieldsValue({
                        firstName: recipient?.firstName,
                        lastName: recipient?.lastName,
                      });
                      setShowCopyProfileName(false);
                      notifySuccess(formatMessage({ id: "containers.tax.nameCopied" }));
                    }}
                  />
                </>
              )}
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="lastName"
              label={formatMessage({ id: "containers.tax.w8.lastName" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.tax.w8.lastNameRequired",
                  }),
                },
                irsRule,
              ]}
            >
              <Form.Input name="lastName" />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="organizationCountry"
              label={formatMessage({
                id: "containers.tax.w8.citizenshipCountry",
              })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.country.required",
                  }),
                },
              ]}
            >
              <Form.SelectCountry type="all" name="organizationCountry" />
            </Form.Field>
          </Grid.Item>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="dob"
              label={formatMessage({ id: "containers.info.dob.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.dob.required",
                  }),
                },
                isLegalAgeDateValidator(),
              ]}
            >
              <Form.DatePicker name="dob" />
            </Form.Field>
          </Grid.Item>
        </Grid>
        <Divider />
        <Title level={3}>{formatMessage({ id: "containers.tax.w8.permanentResidence" })}</Title>
        <Grid>
          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="residenceAddress"
              label={formatMessage({ id: "containers.info.street1.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.street1.required",
                  }),
                },
                irsRule,
              ]}
            >
              {(control, meta, { setFieldsValue }) => (
                <>
                  <Form.Input
                    {...control}
                    name="residenceAddress"
                    onFocus={(e) => {
                      if (!e.target.value && showCopyRecipientAddress === undefined) {
                        setShowCopyRecipientAddress(true);
                      }
                    }}
                  />
                  <CopyProfileAddress
                    label={formatMessage({
                      id: "containers.tax.w8.residenceAddress",
                    })}
                    address={[
                      [recipient?.address.street1, recipient?.address.street2].filter((v) => v).join(" "),
                      stringifyAddress({
                        city: recipient?.address.city,
                        region: getRegionLabel(recipient?.address.region, recipient?.address.country),
                        postalCode: recipient?.address.postalCode,
                      }),
                      formatCountry(recipient?.address.country, formatMessage),
                    ]}
                    visible={!!showCopyRecipientAddress}
                    onClose={() => {
                      setShowCopyRecipientAddress(false);
                    }}
                    onOk={() => {
                      if (recipient) {
                        setFieldsValue({
                          residenceAddress: [recipient?.address.street1, recipient?.address.street2]
                            .filter((v) => v)
                            .join(" "),
                          residenceCity: recipient?.address.city,
                          residenceRegion: recipient?.address.region,
                          residencePostalCode: recipient?.address.postalCode,
                          residenceCountry: recipient?.address.country,
                        });
                        notifySuccess(formatMessage({ id: "containers.tax.addressCopied" }));
                      }
                      setShowCopyRecipientAddress(false);
                    }}
                  />
                </>
              )}
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field
              name="residenceCity"
              label={formatMessage({ id: "containers.info.city.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.city.required",
                  }),
                },
                irsRule,
              ]}
            >
              <Form.Input name="residenceCity" />
            </Form.Field>
          </Grid.Item>

          <Grid.Item xs={24} sm={12}>
            <Form.Field<TaxDataW8BEN>
              name="residenceCountry"
              label={formatMessage({ id: "containers.info.country.title" })}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "containers.info.country.required",
                  }),
                },
                {
                  async validator(rule, value) {
                    if (value === CountryCode.US) {
                      throw formatMessage({ id: "common.invalidValue" });
                    }
                  },
                },
              ]}
            >
              <Form.SelectCountry
                type="all"
                name="residenceCountry"
                excludes={[CountryCode.US]} // person cannot be a US permanent resident, but can be a resident of a US territory. Person can also have US mailing address
                onChange={() => {
                  form.setFieldsValue({
                    residenceRegion: undefined,
                    ftinType: undefined,
                    otherFtinDescription: "",
                    taxPayerForeignId: "",
                  });
                }}
              />
            </Form.Field>
          </Grid.Item>

          <Form.Control dependencies={["residenceCountry"]}>
            {({ value: { residenceCountry } }) => {
              const residenceCountryHasRegions =
                residenceCountry && !!allMappedCountries[residenceCountry]?.regions.length;
              const postalCodeProps = getPostalCodeProps(residenceCountry, formatMessage);

              return (
                <>
                  {residenceCountryHasRegions && (
                    <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                      <Form.Field
                        name="residenceRegion"
                        key={residenceCountry}
                        label={formatMessage({
                          id: getRegionLocaleId(residenceCountry, "title"),
                          defaultMessage: "State / Province",
                        })}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: getRegionLocaleId(residenceCountry, "required"),
                              defaultMessage: "State / Province is required",
                            }),
                          },
                          isRegionInCountryValidator("residenceCountry", formatMessage),
                        ]}
                      >
                        <Form.SelectRegion name="residenceRegion" country={residenceCountry} />
                      </Form.Field>
                    </Grid.Item>
                  )}

                  {postalCodeProps.hasPostalCode && (
                    <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                      <Form.Field
                        name="residencePostalCode"
                        label={formatMessage({
                          id: getPostalLocaleId(residenceCountry, "title"),
                        })}
                        hint={postalCodeProps.hint}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: getPostalLocaleId(residenceCountry, "required"),
                            }),
                          },
                          irsRule,
                        ]}
                      >
                        <Form.Input name="residencePostalCode" />
                      </Form.Field>
                    </Grid.Item>
                  )}
                </>
              );
            }}
          </Form.Control>
        </Grid>
        <Divider />
        <Title level={3}>{formatMessage({ id: "containers.tax.mailingAddress" })}</Title>
        <Form.Checkbox
          name="copyResidence"
          checked={sameAddress}
          onChange={(checked) => {
            if (!checked) {
              form.setFieldsValue({
                mailingCountry: undefined,
                mailingRegion: undefined,
                mailingAddress: undefined,
                mailingCity: undefined,
                mailingPostalCode: undefined,
              });
            }
            setSameAddress(checked);
          }}
        >
          {formatMessage({ id: "containers.tax.w8.sameAsResidence" })}
        </Form.Checkbox>
        <Grid>
          {sameAddress ? (
            <>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingAddress"
                  label={formatMessage({ id: "containers.info.street1.title" })}
                  key="same-mail-add"
                  dependencies={["residenceAddress"]}
                >
                  {(_, meta, { getFieldValue }) => <Form.Input disabled value={getFieldValue("residenceAddress")} />}
                </Form.Field>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field name="mailingCity" label="City" key="same-mail-city" dependencies={["residenceCity"]}>
                  {(_, meta, { getFieldValue }) => <Form.Input disabled value={getFieldValue("residenceCity")} />}
                </Form.Field>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCountry"
                  label={formatMessage({ id: "containers.info.country.title" })}
                  key="same-mail-country"
                  dependencies={["residenceCountry"]}
                >
                  {(_, meta, { getFieldValue }) => (
                    //  W8 cannot be a US permanent resident. but can have US mailing address
                    <Form.SelectCountry disabled type="all" value={getFieldValue("residenceCountry")} />
                  )}
                </Form.Field>
              </Grid.Item>

              <Form.Control dependencies={["residenceCountry"]}>
                {({ value: { residenceCountry } }) => {
                  const residenceCountryHasRegions =
                    residenceCountry && !!allMappedCountries[residenceCountry]?.regions.length;
                  const residenceCountryHasPostalCode = countryHasPostalCode(residenceCountry);

                  return (
                    <>
                      {residenceCountryHasRegions && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                          <Form.Field
                            name="mailingRegion"
                            key={residenceCountry}
                            label={formatMessage({
                              id: getRegionLocaleId(residenceCountry, "title"),
                              defaultMessage: "State / Province",
                            })}
                            dependencies={["residenceRegion"]}
                          >
                            {(_, meta, { getFieldValue }) => (
                              <Form.SelectRegion
                                disabled
                                country={residenceCountry}
                                value={getFieldValue("residenceRegion")}
                              />
                            )}
                          </Form.Field>
                        </Grid.Item>
                      )}
                      {residenceCountryHasPostalCode && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                          <Form.Field
                            name="mailingPostalCode"
                            label={formatMessage({
                              id: getPostalLocaleId(residenceCountry, "title"),
                            })}
                            dependencies={["residencePostalCode"]}
                          >
                            {(_, meta, { getFieldValue }) => (
                              <Form.Input disabled value={getFieldValue("residencePostalCode")} />
                            )}
                          </Form.Field>
                        </Grid.Item>
                      )}
                    </>
                  );
                }}
              </Form.Control>
            </>
          ) : (
            <>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingAddress"
                  label={formatMessage({ id: "containers.info.street1.title" })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.street1.required",
                      }),
                    },
                    irsRule,
                  ]}
                >
                  <Form.Input name="mailingAddress" />
                </Form.Field>
              </Grid.Item>
              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCity"
                  label={formatMessage({ id: "containers.info.city.title" })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.city.required",
                      }),
                    },
                    irsRule,
                  ]}
                >
                  <Form.Input name="mailingCity" />
                </Form.Field>
              </Grid.Item>

              <Grid.Item xs={24} sm={12}>
                <Form.Field
                  name="mailingCountry"
                  label={formatMessage({ id: "containers.info.country.title" })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.country.required",
                      }),
                    },
                  ]}
                >
                  <Form.SelectCountry type="all" name="mailingCountry" />
                </Form.Field>
              </Grid.Item>

              <Form.Control dependencies={["mailingCountry"]}>
                {({ value: { mailingCountry } }) => {
                  const postalCodeProps = getPostalCodeProps(mailingCountry, formatMessage);

                  return (
                    <>
                      {mailingCountry && !!allMappedCountries[mailingCountry]?.regions.length && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 30%" }}>
                          <Form.Field
                            name="mailingRegion"
                            key={mailingCountry}
                            label={formatMessage({
                              id: getRegionLocaleId(mailingCountry, "title"),
                              defaultMessage: "State / Province",
                            })}
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: getRegionLocaleId(mailingCountry, "required"),
                                  defaultMessage: "State / Province is required",
                                }),
                              },
                              isRegionInCountryValidator(["mailingCountry"], formatMessage),
                            ]}
                          >
                            <Form.SelectRegion name="mailingRegion" country={mailingCountry} />
                          </Form.Field>
                        </Grid.Item>
                      )}
                      {postalCodeProps.hasPostalCode && (
                        <Grid.Item xs={24} sm={{ flex: "1 1 20%" }}>
                          <Form.Field
                            name="mailingPostalCode"
                            label={formatMessage({
                              id: getPostalLocaleId(mailingCountry, "title"),
                            })}
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: getPostalLocaleId(mailingCountry, "required"),
                                }),
                              },
                              irsRule,
                            ]}
                            hint={postalCodeProps.hint}
                          >
                            <Form.Input name="mailingPostalCode" />
                          </Form.Field>
                        </Grid.Item>
                      )}
                    </>
                  );
                }}
              </Form.Control>
            </>
          )}
        </Grid>

        <Divider />
        <Title level={3}>{formatMessage({ id: "containers.tax.w8.taxPayerId" })}</Title>

        <Paragraph>
          {formatMessage(
            { id: "containers.tax.w8.provideFTIN" },
            {
              tooltip: (c) => (
                <Tooltip
                  title={formatMessage({
                    id: "containers.tax.w8.usResidentInfo",
                  })}
                >
                  <em>
                    <Text inline underline>
                      {c}
                    </Text>
                    <Icon type="question-circle" />
                  </em>
                </Tooltip>
              ),
            },
          )}
        </Paragraph>

        <Grid>
          <Form.Control
            dependencies={[
              "noTaxId",
              "taxPayerUsId",
              "taxPayerForeignId",
              "residenceCountry",
              "ftinType",
              "otherFtinDescription",
            ]}
          >
            {({
              value: { noTaxId, taxPayerUsId, taxPayerForeignId, residenceCountry, ftinType, otherFtinDescription },
            }) => {
              return (
                <Grid.Item xs={24} sm={18}>
                  <Grid direction="column">
                    <Grid.Item>
                      <Form.Field
                        name="ftinType"
                        label={formatMessage({ id: "containers.tax.w8.fTinType" })}
                        rules={[
                          {
                            required: !noTaxId && !taxPayerUsId && residenceCountry,
                            message: formatMessage({ id: "containers.tax.w8.entityTypeRequired" }),
                          },
                        ]}
                      >
                        <SelectFTINType
                          name="ftinType"
                          disabled={noTaxId || !residenceCountry}
                          country={residenceCountry}
                          addOtherOption
                          showEmptyOption
                          style={{ display: "block" }}
                        />
                      </Form.Field>
                    </Grid.Item>
                    {ftinType !== "other" && (
                      <Grid.Item>
                        <>
                          <TinHelp country={residenceCountry} ftinType={ftinType} />
                          <Divider transparent />
                        </>
                      </Grid.Item>
                    )}
                    {ftinType === "other" && (
                      <Grid.Item>
                        <Form.Field
                          name="otherFtinDescription"
                          label={formatMessage({ id: "containers.tax.w8.otherTinTypeDescription" })}
                          tooltip={formatMessage({ id: "containers.tax.w8.otherTinTypeDescriptionTooltip" })}
                          rules={[
                            {
                              required: !noTaxId && !taxPayerUsId && residenceCountry && ftinType === "other",
                              message: formatMessage({ id: "containers.tax.w8.entityTypeRequired" }),
                            },
                          ]}
                        >
                          <Form.Input name="otherFtinDescription" disabled={noTaxId || !residenceCountry} />
                        </Form.Field>
                      </Grid.Item>
                    )}
                    <Grid.Item>
                      <Form.Field
                        name="taxPayerForeignId"
                        label={
                          residenceCountry && ftinType && ftinType !== "other"
                            ? getFTinLabel(residenceCountry, ftinType)
                            : formatMessage({ id: "containers.tax.fTin" })
                        }
                        rules={
                          noTaxId
                            ? []
                            : [
                                {
                                  required: !taxPayerUsId,
                                  message: formatMessage({ id: "containers.tax.w8.fTinRequired" }),
                                },
                                {
                                  async validator(_: any, value: string) {
                                    if (value && !value.includes("*") && !irsRule.pattern.test(value)) {
                                      throw new Error(irsRule.message);
                                    }
                                  },
                                },
                              ]
                        }
                      >
                        <Form.Input
                          name="taxPayerForeignId"
                          disabled={
                            noTaxId || !residenceCountry || !ftinType || (ftinType === "other" && !otherFtinDescription)
                          }
                        />
                      </Form.Field>
                    </Grid.Item>

                    <Grid.Item>
                      <Form.Field
                        name="taxPayerUsId"
                        label={`${formatMessage({ id: "containers.tax.usTin" })} (${formatMessage({
                          id: "containers.tax.ssnOrItin",
                        })})`}
                        optional
                        rules={
                          noTaxId
                            ? []
                            : [
                                {
                                  async validator(_: any, value: string) {
                                    if (value && !value.includes("*") && value.replace(/\D/g, "").length < 9) {
                                      throw new Error(
                                        formatMessage({ id: "containers.tax.tinValidation" }, { tinName: "SSN" }),
                                      );
                                    }
                                  },
                                },
                              ]
                        }
                      >
                        <Form.InputMask name="taxPayerUsId" disabled={noTaxId} mask={TIN_FORMAT.ssn} />
                      </Form.Field>
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
              );
            }}
          </Form.Control>

          <Grid.Item xs={24}>
            <Form.Field name="noTaxId">
              <Form.Checkbox
                name="noTaxId"
                onChange={(checked) => {
                  if (checked) {
                    form.setFieldsValue({
                      taxPayerUsId: "",
                      taxPayerForeignId: "",
                    });
                  } else {
                    form.setFieldsValue({
                      taxPayerUsId: taxData?.taxPayerUsId || "",
                      taxPayerForeignId: taxData?.taxPayerForeignId || "",
                    });
                  }
                }}
              >
                {formatMessage({ id: "containers.tax.w8.willNotProvideTin" })}
              </Form.Checkbox>
            </Form.Field>
          </Grid.Item>
        </Grid>
        <TaxFooter loading={loading} />
      </Container>
    </Form>
  );
}
