import { getBankRuleProps } from "@trolley/common-frontend";
import React from "react";

import { Form2 as Form } from "components";
import localeBankPayoutMethod from "locale/en/containers/bankPayoutMethod.json";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { getHolderNameWarnings } from "./NameWarning";

export default function InputAccountHolderName() {
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const accountHolderNameRules = getBankRuleProps("accountHolderName", undefined, {}, formatMessage);

  if (!recipient) {
    return null;
  }

  return accountHolderNameRules ? (
    <Form.Item
      name="accountHolderName"
      key="bank_field_accountHolderName"
      label={formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.title",
        defaultMessage: localeBankPayoutMethod.accountHolderName.title,
      })}
      extra={formatMessage({
        id: "containers.bankPayoutMethod.correctName",
        defaultMessage: localeBankPayoutMethod.correctName,
      })}
      rules={[
        ...(accountHolderNameRules.rules || []),
        {
          warningOnly: true,
          async validator(rule, value) {
            if (value) {
              const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, value);

              if (holderNameWarnings.length) {
                throw new Error(holderNameWarnings[0].warningText);
              }
            }
          },
        },
      ]}
    >
      <Form.Input type="text" name="accountHolderName" placeholder="eg. John Smith" />
    </Form.Item>
  ) : null;
}
