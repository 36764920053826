import { CountryCode, RecipientType, formatCountry, getRegionLabel } from "@trolley/common-frontend";
import { FormInstance } from "antd";
import { Button, Card, Container, DateDisplay, Divider, Flag, Form2, Grid, Space, Typography } from "components";
import React from "react";
import { useTheme } from "style/classname";
import { useClientIntegration, useIntl } from "utils/context";
import { stringifyAddress } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import { TaxProfileWizard, WIZARD_SECTIONS } from ".";

interface Props {
  taxProfileWizard: TaxProfileWizard;
  onSubmitTaxProfile: (name: string, exit?: boolean) => void;
  onSummaryEdit: (section: WIZARD_SECTIONS) => void;
}

interface IFormData {
  name: string;
}

export default function Summary({ onSubmitTaxProfile, onSummaryEdit, taxProfileWizard }: Props) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    name: "",
  };
  const { isMobile } = useWindowSize();

  function getTaxIDValue(type: string, country?: CountryCode, value?: string) {
    return {
      label: type,
      value: (
        <Space size={isMobile ? "xsmall" : "small"}>
          {country && value && (
            <>
              <Flag code={country} style={{ verticalAlign: "top" }} />
              {value}
            </>
          )}
        </Space>
      ),
    };
  }
  function getTaxIDListValue(country: CountryCode, value: string, index: number, type: string, total: number) {
    return {
      label: `${type} ${total > 1 ? `# (${index + 1})` : ""}`,
      value: (
        <Space size={isMobile ? "xsmall" : "small"}>
          <Flag code={country} style={{ verticalAlign: "top" }} />
          {value}
        </Space>
      ),
    };
  }

  function onSubmit({ name }: IFormData) {
    onSubmitTaxProfile(name);
  }

  return (
    <Container padding="none">
      <Typography.Title>{formatMessage({ id: "containers.taxProfile.wizard.summaryTitle" })}</Typography.Title>
      <Divider size="large" transparent />
      <SummarySectionWrapper
        title={formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.title" })}
        rows={[
          {
            label: formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.taxProfileType" }),
            value:
              taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
                ? formatMessage({ id: "containers.info.type.individual" })
                : formatMessage({ id: "containers.info.type.business" }),
          },
          {
            label:
              taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
                ? formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.individualName" })
                : formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxProfile.businessName" }),
            value:
              taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
                ? `${taxProfileWizard.recipientFirstName} ${taxProfileWizard.recipientLastName}`
                : taxProfileWizard.businessName,
          },
        ]}
        onEditSection={() => {
          onSummaryEdit(WIZARD_SECTIONS.TAX_PROFILE);
        }}
      />
      <Divider size="large" transparent />
      <SummarySectionWrapper
        title={formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.title" })}
        rows={
          taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
            ? [
                ...(taxProfileWizard.confirmVATNumber === "yes"
                  ? [
                      getTaxIDValue(
                        formatMessage({
                          id: "containers.taxProfile.wizard.summarySection.taxIdentification.vatLabel",
                        }),
                        taxProfileWizard.vatCountry as CountryCode,
                        taxProfileWizard.vatNumber,
                      ),
                    ]
                  : []),
                ...(taxProfileWizard.tinNumbers?.map(({ tinCountry, tinNumber }, index) =>
                  getTaxIDListValue(
                    tinCountry as CountryCode,
                    tinNumber,
                    index,
                    formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.tinLabel" }),
                    taxProfileWizard.tinNumbers?.length ?? 0,
                  ),
                ) ?? []),
              ]
            : [
                ...(taxProfileWizard.confirmVATNumber === "yes"
                  ? [
                      getTaxIDValue(
                        formatMessage({
                          id: "containers.taxProfile.wizard.summarySection.taxIdentification.vatLabel",
                        }),
                        taxProfileWizard.vatCountry as CountryCode,
                        taxProfileWizard.vatNumber,
                      ),
                    ]
                  : []),
                getTaxIDValue(
                  formatMessage({
                    id: "containers.taxProfile.wizard.summarySection.taxIdentification.businessRNLabel",
                  }),
                  taxProfileWizard.businessCountry as CountryCode,
                  taxProfileWizard.businessNumber,
                ),
                ...(taxProfileWizard.tinNumbers?.map(({ tinCountry, tinNumber }, index) =>
                  getTaxIDListValue(
                    tinCountry as CountryCode,
                    tinNumber,
                    index,
                    formatMessage({ id: "containers.taxProfile.wizard.summarySection.taxIdentification.tinLabel" }),
                    taxProfileWizard.tinNumbers?.length ?? 0,
                  ),
                ) ?? []),
              ]
        }
        onEditSection={() => {
          onSummaryEdit(WIZARD_SECTIONS.TAX_IDENTIFICATION);
        }}
      />
      <Divider size="large" transparent />
      <SummarySectionWrapper
        title={
          taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
            ? formatMessage({ id: "containers.taxProfile.wizard.summarySection.details.individualTitle" })
            : formatMessage({ id: "containers.taxProfile.wizard.summarySection.details.bussinessTitle" })
        }
        rows={
          taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL
            ? [
                ...(taxProfileWizard.tinNumbers?.length === 0
                  ? [
                      {
                        label: formatMessage({
                          id: "containers.taxProfile.wizard.summarySection.details.placeOfBirthLabel",
                        }),
                        value: `${taxProfileWizard.birthplace?.city}, ${formatCountry(
                          taxProfileWizard.birthplace?.country,
                        )}`,
                      },
                    ]
                  : []),
                {
                  label: formatMessage({
                    id: "containers.taxProfile.wizard.summarySection.details.dateOfBirthLabel",
                  }),
                  value: <DateDisplay value={taxProfileWizard.dob} time={false} />,
                },
                {
                  label: formatMessage({ id: "containers.taxProfile.wizard.summarySection.details.addressLabel" }),
                  value: stringifyAddress({
                    ...taxProfileWizard.address,
                    country: formatCountry(taxProfileWizard.address?.country),
                    region: getRegionLabel(taxProfileWizard.address?.region, taxProfileWizard.address?.country),
                  }),
                },
              ]
            : [
                {
                  label: formatMessage({ id: "containers.taxProfile.wizard.summarySection.details.addressLabel" }),
                  value: stringifyAddress({
                    ...taxProfileWizard.address,
                    country: formatCountry(taxProfileWizard.address?.country),
                    region: getRegionLabel(taxProfileWizard.address?.region, taxProfileWizard.address?.country),
                  }),
                },
              ]
        }
        onEditSection={() => {
          onSummaryEdit(WIZARD_SECTIONS.DETAILS);
        }}
      />
      <Divider size="large" transparent />
      <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
        <fieldset>
          <legend style={{ border: 0 }}>
            <Typography.Text type="label" size="base">
              {taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL &&
              !!taxProfileWizard.recipientFirstName &&
              !!taxProfileWizard.recipientLastName
                ? formatMessage(
                    { id: "containers.taxProfile.wizard.summaryCertifiedBy" },
                    { name: `${taxProfileWizard.recipientFirstName} ${taxProfileWizard.recipientLastName}` },
                  )
                : formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByFullName" })}
            </Typography.Text>
          </legend>
          <Form2.Item
            name="name"
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByRequiredMessage" }),
              },
              {
                async validator(_: any, v: any) {
                  if (
                    taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL &&
                    v?.trim() !== `${taxProfileWizard.recipientFirstName} ${taxProfileWizard.recipientLastName}`
                  ) {
                    throw new Error(
                      formatMessage({ id: "containers.taxProfile.wizard.summaryCertifiedByMustMatchMessage" }),
                    );
                  }
                },
              },
            ]}
          >
            <Form2.Input type="text" />
          </Form2.Item>
        </fieldset>
        <Divider transparent />
        <Form2.Item dependencies={["name"]}>
          {(form: FormInstance<IFormData>) => {
            const name = form.getFieldValue("name");

            return <Form2.SubmitButton disabled={!name} text={formatMessage({ id: "common.save" })} />;
          }}
        </Form2.Item>
      </Form2>
    </Container>
  );
}

function SummarySectionWrapper({
  onEditSection,
  title,
  rows,
}: {
  onEditSection: () => void;
  title: string;
  rows: { label: string; value: React.ReactNode }[];
}) {
  const token = useTheme();
  const { formatMessage } = useIntl();
  const client = useClientIntegration();

  return (
    <Card
      type="inner"
      title={<Typography.Text>{title}</Typography.Text>}
      extra={
        client === "portal" ? (
          <Button
            type="text"
            icon="pen-to-square"
            aria-label={`${title} - ${formatMessage({ id: "common.edit" })}`}
            size="small"
            onClick={() => onEditSection()}
          />
        ) : (
          <Button
            style={{
              marginRight: "12px",
              background: token.colorFillTertiary,
              border: `1px solid ${token.colorBorder}`,
            }}
            onClick={() => onEditSection()}
          >
            {formatMessage({ id: "common.edit" })}
          </Button>
        )
      }
    >
      <dl>
        <Grid padding="medium">
          {rows.map(({ label, value }) => (
            <React.Fragment key={label}>
              <Grid.Item xs={11}>
                <dt>
                  <Typography.Text type="label">{label}</Typography.Text>
                </dt>
              </Grid.Item>
              <Grid.Item xs={13}>
                <dd>{value}</dd>
              </Grid.Item>
            </React.Fragment>
          ))}
        </Grid>
      </dl>
    </Card>
  );
}
